import storage from 'redux-persist/lib/storage'
import { createMigrate } from 'redux-persist'
import {
  createFilter,
  createBlacklistFilter,
} from 'redux-persist-transform-filter'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'

import migrations from '../Redux/Migrations'

// createFilter: for saving subset of reducer : ignoring everything else
// createBlacklistFilter: for blacklisting subset of reducer : save everything else

const saveSubsetFilterA = createBlacklistFilter('canvas', [
  'canvasLoadError',
  'canvasSaveError',
  'canvasDeleteError',
  'archiveLoadError',
  'latestVersionCanvasData',
  'latestVersionCanvasId',
  'latestVersionCanvasMetaData',
  'newVersionAvailable',
])

const REDUX_PERSIST = {
  active: true,
  version: 0, // update this on each new added migration
  key: 'primary',
  storage: storage,
  // Reducer keys that you do NOT want stored to persistence here.
  stateReconciler: autoMergeLevel2,
  blacklist: ['networkStatus'],
  // Optionally, just specify the keys you DO want stored to persistence.
  // An empty array means 'don't store any reducers'
  // whitelist: [],
  transforms: [saveSubsetFilterA],
  migrate: createMigrate(migrations, { debug: false }),
}

export default REDUX_PERSIST

import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'

class NoMatch extends Component {
  render() {
    return (
      <div style={styles.container}>
        <Link to="/">Go Home!!</Link>
      </div>
    )
  }
}
export default withRouter(NoMatch)

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    flexDirection: 'column',
  },
}

import { call, put, takeLatest } from 'redux-saga/effects'
import { CanvasTypes } from '../Redux/CanvasRedux'
import CanvasActions from '../Redux/CanvasRedux'
import * as API from '../Services/API'
import { message } from 'antd'

/* ------------- Worker Sagas ------------- */

function* saveCanvasToCloud(action) {
  const { data } = action
  try {
    // sets loading to true
    yield put(CanvasActions.saveCanvasToCloudPending())
    // makes api call
    const response = yield call(API.saveCanvasToCloud, { data })
    // dispatch a success action to the store with the new signup
    yield put(CanvasActions.saveCanvasToCloudFulfilled(response))
    message.success('Canvas saved to Cloud.')
  } catch (error) {
    // console.log('====================================')
    // console.log('saveCanvasToCloud ERROR:', error)
    // console.log('====================================')
    if (typeof error === 'string') {
      message.error(error)
    } else {
      message.error('Error Saving Canvas to Cloud.')
    }
    // dispatch a failure action to the store with the error
    yield put(CanvasActions.saveCanvasToCloudRejected(error))
  }
}

function* loadCanvasFromCloud(action) {
  const { data } = action

  try {
    // sets loading to true
    yield put(CanvasActions.loadCanvasFromCloudPending())
    // makes api call
    const response = yield call(API.loadLatestCanvasVersionFromCloud, { data })
    // dispatch a success action to the store with the new signup
    yield put(CanvasActions.loadCanvasFromCloudFulfilled(response))
  } catch (error) {
    // console.log('====================================')
    // console.log('loadCanvasFromCloud ERROR:', error)
    // console.log('====================================')
    if (typeof error === 'string') {
      // message.error(error)
    } else {
      // message.error('Error Loading Canvas from Cloud.')
    }
    // dispatch a failure action to the store with the error
    yield put(CanvasActions.loadCanvasFromCloudRejected(error))
  }
}

function* saveCanvasVisibilityToCloud(action) {
  const { data } = action
  const { canvasId, visibility } = data

  try {
    // sets loading to true
    yield put(CanvasActions.saveCanvasVisibilityToCloudPending())
    // makes api call
    const response = yield call(API.saveCanvasVisibilityToCloud, { data })
    // dispatch a success action to the store with the new signup
    yield put(CanvasActions.saveCanvasVisibilityToCloudFulfilled(response))
    message.success('Canvas visibility updated in Cloud.')
  } catch (error) {
    // console.log('====================================')
    // console.log('saveCanvasVisibilityToCloud ERROR:', error)
    // console.log('====================================')
    if (typeof error === 'string') {
      message.error(error)
    } else {
      message.error('Error updating Canvas visibility.')
    }
    // dispatch a failure action to the store with the error
    yield put(CanvasActions.saveCanvasVisibilityToCloudRejected(error))
  }
}

function* loadCanvasTopLevelDataFromCloud(action) {
  const { data } = action
  try {
    // sets loading to true
    yield put(CanvasActions.loadCanvasTopLevelDataFromCloudPending())
    // makes api call
    const response = yield call(API.loadCanvasTopLevelDataFromCloud, { data })
    // dispatch a success action to the store with the new signup
    yield put(CanvasActions.loadCanvasTopLevelDataFromCloudFulfilled(response))
  } catch (error) {
    // console.log('====================================')
    // console.log('loadCanvasTopLevelDataFromCloud ERROR:', error)
    // console.log('====================================')
    // dispatch a failure action to the store with the error
    yield put(CanvasActions.loadCanvasTopLevelDataFromCloudRejected(error))
  }
}

function* deleteCanvasFromCloud(action) {
  const { data } = action
  try {
    // sets loading to true
    yield put(CanvasActions.deleteCanvasFromCloudPending())
    // makes api call
    const response = yield call(API.deleteCanvasFromCloud, { data })
    // dispatch a success action to the store with the new signup
    yield put(CanvasActions.deleteCanvasFromCloudFulfilled(response))
    message.success('Canvas deleted from Cloud.')
  } catch (error) {
    // console.log('====================================')
    // console.log('deleteCanvasFromCloud ERROR:', error)
    // console.log('====================================')
    // dispatch a failure action to the store with the error
    yield put(CanvasActions.deleteCanvasFromCloudRejected(error))
  }
}

function* saveCanvasSharedUserPermissionsToCloud(action) {
  const { data } = action

  try {
    // sets loading to true
    yield put(CanvasActions.saveCanvasSharedUserPermissionsToCloudPending())
    // makes api call
    const response = yield call(API.saveCanvasSharedUserPermissionsToCloud, {
      data,
    })
    // dispatch a success action to the store with the new signup
    yield put(
      CanvasActions.saveCanvasSharedUserPermissionsToCloudFulfilled(response),
    )
    message.success('User successfully added to shared list.')
  } catch (error) {
    // console.log('====================================')
    // console.log('saveCanvasSharedUserPermissionsToCloud ERROR:', error)
    // console.log('====================================')
    if (typeof error === 'string') {
      message.error(error)
    } else {
      message.error(
        'User does not exist with that email. Only users with account can be added to shared list.',
      )
    }
    // dispatch a failure action to the store with the error
    yield put(
      CanvasActions.saveCanvasSharedUserPermissionsToCloudRejected(error),
    )
  }
}

function* deleteCanvasSharedUserPermissionsFromCloud(action) {
  const { data } = action

  try {
    // sets loading to true
    yield put(CanvasActions.deleteCanvasSharedUserPermissionsFromCloudPending())
    // makes api call
    const response = yield call(
      API.deleteCanvasSharedUserPermissionsFromCloud,
      {
        data,
      },
    )
    // dispatch a success action to the store with the new signup
    yield put(
      CanvasActions.deleteCanvasSharedUserPermissionsFromCloudFulfilled(
        response,
      ),
    )
    message.success('User successfully deleted from shared list.')
  } catch (error) {
    // console.log('====================================')
    // console.log('deleteCanvasSharedUserPermissionsFromCloud ERROR:', error)
    // console.log('====================================')
    if (typeof error === 'string') {
      message.error(error)
    } else {
    }
    // dispatch a failure action to the store with the error
    yield put(
      CanvasActions.deleteCanvasSharedUserPermissionsFromCloudRejected(error),
    )
  }
}

function* loadCanvasSharedPermissionsFromCloud(action) {
  const { data } = action

  try {
    // sets loading to true
    yield put(CanvasActions.loadCanvasSharedPermissionsFromCloudPending())
    // makes api call
    const response = yield call(API.loadCanvasSharedPermissionsFromCloud, {
      data,
    })
    // dispatch a success action to the store with the new signup
    yield put(
      CanvasActions.loadCanvasSharedPermissionsFromCloudFulfilled(response),
    )
  } catch (error) {
    // console.log('====================================')
    // console.log('loadCanvasSharedPermissionsFromCloud ERROR:', error)
    // console.log('====================================')
    if (typeof error === 'string') {
      message.error(error)
    } else {
    }
    // dispatch a failure action to the store with the error
    yield put(CanvasActions.loadCanvasSharedPermissionsFromCloudRejected(error))
  }
}

/* ------------- Watcher Sagas ------------- */
export const canvasSagas = [
  // some sagas only receive an action
  // some sagas only receive an action
  takeLatest(CanvasTypes.SAVE_CANVAS_TO_CLOUD_REQUEST, saveCanvasToCloud),
  takeLatest(CanvasTypes.LOAD_CANVAS_FROM_CLOUD_REQUEST, loadCanvasFromCloud),
  takeLatest(
    CanvasTypes.SAVE_CANVAS_VISIBILITY_TO_CLOUD_REQUEST,
    saveCanvasVisibilityToCloud,
  ),
  takeLatest(
    CanvasTypes.LOAD_CANVAS_TOP_LEVEL_DATA_FROM_CLOUD_REQUEST,
    loadCanvasTopLevelDataFromCloud,
  ),
  takeLatest(
    CanvasTypes.DELETE_CANVAS_FROM_CLOUD_REQUEST,
    deleteCanvasFromCloud,
  ),
  takeLatest(
    CanvasTypes.SAVE_CANVAS_SHARED_USER_PERMISSIONS_TO_CLOUD_REQUEST,
    saveCanvasSharedUserPermissionsToCloud,
  ),
  takeLatest(
    CanvasTypes.DELETE_CANVAS_SHARED_USER_PERMISSIONS_FROM_CLOUD_REQUEST,
    deleteCanvasSharedUserPermissionsFromCloud,
  ),
  takeLatest(
    CanvasTypes.LOAD_CANVAS_SHARED_PERMISSIONS_FROM_CLOUD_REQUEST,
    loadCanvasSharedPermissionsFromCloud,
  ),
]

import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import LeanCanvas from '../Containers/LeanCanvas'
import Archive from '../Containers/Archive'
import NoMatch from '../Containers/NoMatch'
import ProtectedRoute from './ProtectedRoute'

const RootNavigation = () => {
  return (
    <Switch>
      <Route exact path="/" component={LeanCanvas} />
      <ProtectedRoute exact path="/archive" component={Archive} />
      <Route path="/:canvasId" component={LeanCanvas} />

      {/* when none of the above match, <NoMatch> will be rendered */}
      <Route component={NoMatch} />
    </Switch>
  )
}
export default RootNavigation

import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import html2canvas from 'html2canvas'
import FileSaver from 'file-saver'
import AuthActions from '../Redux/AuthRedux'
import CanvasActions from '../Redux/CanvasRedux'
import {
	Button,
	Badge,
	Table,
	Divider,
	Tag,
	Popconfirm,
	Avatar,
	message,
	Modal,
	Typography,
	Radio,
	Popover,
	Input,
	Icon,
	Select,
	Spin,
	Tooltip,
} from 'antd'
import Helpers from '../Utils/Helpers'
import { addListenerOnCanvas, addListenerOnUser } from '../Services/API'
import { CopyToClipboard } from 'react-copy-to-clipboard'
const { TextArea } = Input
const { Text, Paragraph } = Typography
const InputGroup = Input.Group
const Option = Select.Option
class LeanCanvas extends Component {
	state = {
		showSettingsModal: false,
		showCreatingBlankCanvasModal: false,
		showDeleteCanvasModal: false,
		showSignOutModal: false,
		showShareSettingsModal: false,
		dirtyCanvas: false,
		handledLoadError: false,
		unsubscribe: null,
		clipBoardCopied: false,
		retriedListener: false,
		shared_permission: 'can_view',
		shared_user_email: '',
		newCanvas: true,
		userHasViewPermissionForSavedCanvas: true,
		userHasEditPermissionForSavedCanvas: true,
		userOwnsSavedCanvas: true,
	}

	componentDidMount() {
		this.handleCanvasId()
		const { auth } = this.props
		const { user } = auth
		if (user) {
			addListenerOnUser(user)
		}
	}

	handleCanvasId = () => {
		const { match, canvas } = this.props
		if (match.params && match.params.canvasId) {
			const { canvasId: urlCanvasId } = match.params
			this.loadCanvasFromCloudRequest(urlCanvasId)
			this.loadCanvasTopLevelDataFromCloudRequest(urlCanvasId)
			this.handleAddingCanvasListener(urlCanvasId)
		} else if (canvas && canvas.canvasData && canvas.canvasId) {
			this.loadCanvasFromCloudRequest(canvas.canvasId)
			this.loadCanvasTopLevelDataFromCloudRequest(canvas.canvasId)
			this.props.history.push(`/${canvas.canvasId}`)
			// message.info(`Loaded last working Canvas`)
			this.handleAddingCanvasListener(canvas.canvasId)
		} else {
			this.generateNewCanvas()
		}
	}

	generateNewCanvas = () => {
		const canvasId = Helpers.generateCanvasId()
		this.handleCanvasClear()
		this.handleHideModal()
		this.props.setCanvasId({ data: canvasId })
		this.props.history.push(`/${canvasId}`)
		this.handleAddingCanvasListener(canvasId)
	}

	handleAddingCanvasListener = (canvasId) => {
		// console.log('handleAddingCanvasListener ran with Id:', canvasId)
		if (this.state.unsubscribe) {
			this.state.unsubscribe()
		}
		const unsubscribe = addListenerOnCanvas(
			canvasId,
			this.canvasListenerEventCallback
		)
		this.setState({ unsubscribe })
	}

	canvasListenerEventCallback = (
		error,
		{ canvasId: latestVersionCanvasId, changedCanvas }
	) => {
		const { canvas } = this.props
		const { canvasId, canvasMetaData, newVersionAvailable = false } = canvas
		if (
			!error &&
			canvasId === latestVersionCanvasId &&
			canvasMetaData &&
			changedCanvas &&
			canvasMetaData.version &&
			changedCanvas.version &&
			canvasMetaData.version < changedCanvas.version
		) {
			// console.log('storeLatestVersionCanvasLocally called in callback')
			this.props.storeLatestVersionCanvasLocally({ data: changedCanvas })
		} else {
			// console.log('canvasListenerEventCallback Error:', error)
			if (
				canvasId &&
				canvasId === latestVersionCanvasId &&
				canvasMetaData &&
				error
			) {
				this.generateNewCanvas()
				message.info(
					'You no longer have permission to access this Canvas. Loading Blank New Canvas.'
				)
			}
		}
	}

	handleNewVersionPromptShow = () => {
		const { canvas } = this.props
		const {
			canvasMetaData,
			latestVersionCanvasMetaData,
			fetching,
			canvasId,
			latestVersionCanvasId,
			newVersionAvailable,
		} = canvas

		if (
			canvasMetaData &&
			latestVersionCanvasMetaData &&
			latestVersionCanvasId === canvasId
		) {
			const { version: currentVersion } = canvasMetaData
			const { version: latestVersion } = latestVersionCanvasMetaData

			if (
				latestVersion !== currentVersion &&
				!fetching &&
				!newVersionAvailable &&
				latestVersion > currentVersion
			) {
				// new version was added
				this.updateNewCanvasVersionAvailable({
					newVersionAvailable: true,
				})
			} else if (newVersionAvailable && latestVersion <= currentVersion) {
				this.updateNewCanvasVersionAvailable({
					newVersionAvailable: false,
				})
			}
		}
	}

	handleCanvasLoadError = () => {
		const { canvas } = this.props
		const { canvasId } = canvas
		if (!this.state.handledLoadError) {
			this.setState({ handledLoadError: true }, () => {
				if (canvas && canvas.canvasData && canvas.canvasId) {
					this.props.history.push(`/${canvasId}`)
					message.info(`Last Working Canvas Loaded.`)
					this.handleAddingCanvasListener(canvasId)
				} else {
					this.generateNewCanvas()
					message.info('Error loading Canvas. Loading Blank New Canvas.')
				}
			})
		}
	}

	deleteCanvasFromCloudRequest = () => {
		const { canvas } = this.props
		const { canvasId } = canvas
		const data = { canvasId }
		// console.log('User Wants to Delete the following Canvas with Id: ', canvasId)
		this.props.deleteCanvasFromCloudRequest({ data })
		this.props.clearCanvasLocally()
		this.generateNewCanvas()

		this.setState({ showDeleteCanvasModal: false, retriedListener: false })
	}

	signinWithGoogleRequest = () => {
		this.props.signinWithGoogleRequest()
	}

	handleSignOut = () => {
		this.signoutRequest()
		this.handleHideModal()
	}
	signoutRequest = () => {
		const { canvas } = this.props
		const { canvasId } = canvas
		this.handleAddingCanvasListener(canvasId)
		this.props.signoutRequest()
		this.generateNewCanvas()
	}

	download = () => {
		const input = document.getElementById('divToPrint')
		html2canvas(input).then((canvas) => {
			const imgData = canvas.toDataURL('image/png')
			FileSaver.saveAs(imgData, 'LeanStartupCanvas.png')
			this.handleHideModal()
		})
	}

	onChange = (e) => {
		let { canvasData, canvasMetaData } = this.props.canvas
		const id = e.target.id
		canvasData[id] = e.target.value
		const data = { canvasData, canvasMetaData }
		this.updateCanvasLocally({ data })
		const {
			problem,
			solution,
			keyMetrics,
			uniqueValueProp,
			unfairAdvantage,
			channels,
			customerSegments,
			costStructure,
			revenueStreams,
		} = canvasData
		if (
			problem === '' &&
			solution === '' &&
			keyMetrics === '' &&
			uniqueValueProp === '' &&
			uniqueValueProp === '' &&
			unfairAdvantage === '' &&
			channels === '' &&
			problem === '' &&
			customerSegments === '' &&
			costStructure === '' &&
			revenueStreams === ''
		) {
			// message.info('Canvas Already Empty.')
		} else {
			this.setState({ dirtyCanvas: true })
		}
	}

	updateCanvasLocally = ({ data }) => {
		this.props.updateCanvasLocally({ data })
	}

	updateCanvasTitleLocally = ({ data }) => {
		this.props.updateCanvasTitleLocally({ data })
	}

	generateCanvasDataObject = () => {
		// const canvasId = this.props.canvas.canvasId
		const { canvas } = this.props
		const { canvasData, canvasTitle = 'Untitled Canvas', ...otherData } = canvas
		const {
			problem = '',
			solution = '',
			keyMetrics = '',
			uniqueValueProp = '',
			unfairAdvantage = '',
			channels = '',
			customerSegments = '',
			costStructure = '',
			revenueStreams = '',
		} = this.props.canvas.canvasData
		const cleanedCanvasData = {
			problem: problem.trim(),
			solution: solution.trim(),
			keyMetrics: keyMetrics.trim(),
			uniqueValueProp: uniqueValueProp.trim(),
			unfairAdvantage: unfairAdvantage.trim(),
			channels: channels.trim(),
			customerSegments: customerSegments.trim(),
			costStructure: costStructure.trim(),
			revenueStreams: revenueStreams.trim(),
		}
		const data = {
			canvasData: cleanedCanvasData,
			canvasTitle: canvasTitle.trim(),
			...otherData,
		}
		return data
	}

	saveCanvasToCloudRequest = () => {
		const { auth, canvas, canvasId } = this.props
		const { canvasMetaData } = canvas
		const { user } = auth
		const sharedCanvases =
			user && user.sharedCanvases ? user.sharedCanvases : {}
		const data = this.generateCanvasDataObject()
		const { canvasData, canvasTitle } = data
		const {
			userOwnsSavedCanvas,
			userHasViewPermissionForSavedCanvas,
			userHasEditPermissionForSavedCanvas,
			newCanvas,
		} = this.state

		const {
			problem = '',
			solution = '',
			keyMetrics = '',
			uniqueValueProp = '',
			unfairAdvantage = '',
			channels = '',
			customerSegments = '',
			costStructure = '',
			revenueStreams = '',
		} = canvasData
		if (!user) {
			message.error('Save to Cloud Requires a Signed In User.')
		} else if (
			user &&
			problem === '' &&
			solution === '' &&
			keyMetrics === '' &&
			uniqueValueProp === '' &&
			unfairAdvantage === '' &&
			channels === '' &&
			customerSegments === '' &&
			costStructure === '' &&
			revenueStreams === '' &&
			canvasTitle === 'Untitled Canvas'
		) {
			message.error('Saving Empty Canvas is not allowed.')
		} else if (
			!newCanvas &&
			!userOwnsSavedCanvas &&
			!userHasEditPermissionForSavedCanvas
		) {
			message.error(`View Only Canvas can't be saved to cloud.`)
		} else if (
			newCanvas ||
			userOwnsSavedCanvas ||
			userHasEditPermissionForSavedCanvas
		) {
			const { canvasId } = data
			this.props.saveCanvasToCloudRequest({ data })
			this.handleAddingCanvasListener(canvasId)
		} else {
			message.error('Error occurred saving Canvas to cloud.')
		}
	}

	handleRetryAddingListener = () => {
		const { canvas } = this.props
		const { canvasId, canvasMetaData } = canvas
		if (!this.state.retriedListener && canvasMetaData && canvasId) {
			this.handleAddingCanvasListener(canvasId)
			this.setState({ retriedListener: true })
		}
	}

	loadCanvasFromCloudRequest = (canvasId) => {
		this.props.loadCanvasFromCloudRequest({ data: { canvasId } })
	}

	loadCanvasTopLevelDataFromCloudRequest = (canvasId) => {
		this.props.loadCanvasTopLevelDataFromCloudRequest({ data: { canvasId } })
	}

	handleCreatingBlankCanvas = () => {
		this.generateNewCanvas()
		message.info('Loaded Blank New Canvas.')
		this.setState({
			showCreatingBlankCanvasModal: false,
			retriedListener: false,
		})
	}

	handleCanvasClear = () => {
		const {
			problem,
			solution,
			keyMetrics,
			uniqueValueProp,
			unfairAdvantage,
			channels,
			customerSegments,
			costStructure,
			revenueStreams,
		} = this.props.canvas.canvasData
		if (
			problem === '' &&
			solution === '' &&
			keyMetrics === '' &&
			uniqueValueProp === '' &&
			uniqueValueProp === '' &&
			unfairAdvantage === '' &&
			channels === '' &&
			problem === '' &&
			customerSegments === '' &&
			costStructure === '' &&
			revenueStreams === ''
		) {
			this.setState({
				dirtyCanvas: false,
			})
			this.props.clearCanvasLocally()
		} else {
			this.setState({
				dirtyCanvas: false,
			})
			this.props.clearCanvasLocally()
		}
	}

	loadCanvasSharedPermissionsFromCloudRequest = ({ data }) => {
		this.props.loadCanvasSharedPermissionsFromCloudRequest({
			data,
		})
	}

	handleShowSettingsModal = async () => {
		this.setState({
			showSettingsModal: true,
		})
		const { canvas, auth } = this.props
		const { user } = auth
		const { canvasMetaData, canvasId } = canvas
		if (user && canvas && canvasMetaData && canvasMetaData.owner === user.uid) {
			this.setState({ shared_user_email: '', shared_permission: 'can_view' })

			const data = { canvasId }
			this.loadCanvasSharedPermissionsFromCloudRequest({
				data,
			})
		}
	}

	handleShowShareSettingsModal = () => {
		this.setState({
			showShareSettingsModal: true,
		})
		const { canvas, auth } = this.props
		const { user } = auth
		const { canvasMetaData, canvasId } = canvas
		if (user && canvas && canvasMetaData && canvasMetaData.owner === user.uid) {
			this.setState({ shared_user_email: '', shared_permission: 'can_view' })

			const data = { canvasId }
			this.loadCanvasSharedPermissionsFromCloudRequest({
				data,
			})
		}
	}

	handleShowDeleteCanvasModal = () => {
		this.setState({
			showDeleteCanvasModal: true,
		})
	}

	handleHideModal = () => {
		this.setState(
			{
				showSettingsModal: false,
				showShareSettingsModal: false,
				showCreatingBlankCanvasModal: false,
				showSignOutModal: false,
				showDeleteCanvasModal: false,
			},
			() => {
				this.setState({
					clipBoardCopied: false,
				})
			}
		)
	}

	handleVisibilityToggle = (value) => {
		const { canvas } = this.props
		const { visibility } = canvas
		if (visibility !== value) {
			// update in cloud here
			this.saveCanvasVisibilityToCloudRequest({ visibility: value })
		}
	}

	saveCanvasVisibilityToCloudRequest = ({ visibility }) => {
		const { canvas } = this.props
		const { canvasId } = canvas
		const data = { canvasId, visibility }

		this.props.saveCanvasVisibilityToCloudRequest({ data })
	}

	loadLatestVersionOfCanvas = () => {
		const { canvas } = this.props
		const {
			latestVersionCanvasMetaData,
			latestVersionCanvasData,
			latestVersionCanvasTitle,
		} = canvas
		const data = {
			canvasData: latestVersionCanvasData,
			canvasMetaData: latestVersionCanvasMetaData,
		}
		this.updateCanvasTitleLocally({
			data: { canvasTitle: latestVersionCanvasTitle },
		})
		this.updateCanvasLocally({ data })
		this.updateNewCanvasVersionAvailable({ newVersionAvailable: false })
	}

	updateNewCanvasVersionAvailable = ({ newVersionAvailable }) => {
		const data = { newVersionAvailable }
		this.props.updateNewCanvasVersionAvailable({ data })
	}

	handleShowCreatingBlankCanvasModal = () => {
		this.setState({
			showCreatingBlankCanvasModal: true,
		})
	}

	handleShowSignOutModal = () => {
		this.setState({
			showSignOutModal: true,
		})
	}

	setUserPermissionsForCanvas = () => {
		const { auth, canvas } = this.props
		const { canvasMetaData, canvasId } = canvas
		const { user } = auth
		const sharedCanvases =
			user && user.sharedCanvases ? user.sharedCanvases : {}

		const updatedUserOwnsSavedCanvas = !!(
			user &&
			user.uid &&
			canvas &&
			canvasMetaData &&
			canvasMetaData.owner === user.uid
		)

		const updatedUserHasEditPermissionForSavedCanvas = !!(
			user &&
			user.uid &&
			canvas &&
			canvasId &&
			((sharedCanvases[canvasId] &&
				sharedCanvases[canvasId].permission === 'can_edit') ||
				(canvas.visibility && canvas.visibility === 'public_edit') ||
				(canvasMetaData && canvasMetaData.owner === user.uid))
		)

		const updatedUserHasViewPermissionForSavedCanvas = !!(
			(user &&
				user.uid &&
				canvas &&
				canvasId &&
				((sharedCanvases[canvasId] &&
					(sharedCanvases[canvasId].permission === 'can_edit' ||
						sharedCanvases[canvasId].permission === 'can_comment' ||
						sharedCanvases[canvasId].permission === 'can_view')) ||
					(canvasMetaData && canvasMetaData.owner === user.uid))) ||
			(canvas.visibility &&
				(canvas.visibility === 'public_edit' ||
					canvas.visibility === 'public_comment' ||
					canvas.visibility === 'public_view'))
		)

		const updatedNewCanvas = !!(
			!canvasMetaData ||
			(canvasMetaData && !canvasMetaData.owner)
		)

		const {
			newCanvas,
			userHasViewPermissionForSavedCanvas,
			userHasEditPermissionForSavedCanvas,
			userOwnsSavedCanvas,
		} = this.state
		if (newCanvas !== updatedNewCanvas) {
			this.setState({ newCanvas: updatedNewCanvas })
		}
		if (
			userHasViewPermissionForSavedCanvas !==
			updatedUserHasViewPermissionForSavedCanvas
		) {
			this.setState({
				userHasViewPermissionForSavedCanvas:
					updatedUserHasViewPermissionForSavedCanvas,
			})
		}
		if (
			userHasEditPermissionForSavedCanvas !==
			updatedUserHasEditPermissionForSavedCanvas
		) {
			this.setState({
				userHasEditPermissionForSavedCanvas:
					updatedUserHasEditPermissionForSavedCanvas,
			})
		}
		if (userOwnsSavedCanvas !== updatedUserOwnsSavedCanvas) {
			this.setState({ userOwnsSavedCanvas: updatedUserOwnsSavedCanvas })
		}
	}

	canvasTitleOnChange = (canvasTitle = '') => {
		if (canvasTitle.trim() === '') {
			message.error(`Canvas title can't be empty.`)
		} else if (canvasTitle.length <= 62) {
			const data = { canvasTitle }
			this.updateCanvasTitleLocally({ data })
		} else {
			message.error(`Canvas title can't be too long.`)
		}
	}

	handleDeleteCanvasSharedUserPermissionsFromCloudRequest = (e) => {
		let shared_user_email = e.target.value || ''
		shared_user_email = shared_user_email.trim().toLowerCase()
		if (shared_user_email !== '') {
			const { canvas } = this.props
			const { canvasId } = canvas
			const data = { canvasId, shared_user_email }

			this.deleteCanvasSharedUserPermissionsFromCloudRequest({ data })
			this.setState({ shared_user_email: '', shared_permission: 'can_view' })
		}
	}

	handleSaveCanvasSharedUserPermissionsToCloudRequest = () => {
		let { shared_user_email = '', shared_permission } = this.state
		shared_user_email = shared_user_email.trim().toLowerCase()

		const { user } = this.props.auth
		const currentUserEmail = user && user.email ? user.email : ''
		if (shared_user_email !== '' && shared_permission) {
			const { canvas } = this.props
			const { canvasId } = canvas
			const data = { canvasId, shared_user_email, shared_permission }
			if (currentUserEmail === shared_user_email) {
				message.error(`You don't need to add yourself to shared list.`)
			} else if (!Helpers.validateEmail(shared_user_email)) {
				message.error(`Please enter a valid email.`)
			} else {
				this.saveCanvasSharedUserPermissionsToCloudRequest({ data })
				this.setState({ shared_user_email: '', shared_permission: 'can_view' })
			}
		} else {
			message.error(`User email is required for updating shared permissions.`)
		}
	}

	saveCanvasSharedUserPermissionsToCloudRequest = ({ data }) => {
		this.props.saveCanvasSharedUserPermissionsToCloudRequest({ data })
	}

	deleteCanvasSharedUserPermissionsFromCloudRequest = ({ data }) => {
		this.props.deleteCanvasSharedUserPermissionsFromCloudRequest({ data })
	}

	renderSettings = () => {
		const { auth } = this.props
		const { canvas } = this.props
		const { canvasMetaData, canvasSharedPermissions } = canvas
		const { user } = auth
		const {
			userOwnsSavedCanvas,
			userHasViewPermissionForSavedCanvas,
			userHasEditPermissionForSavedCanvas,
			newCanvas,
		} = this.state
		return (
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
					width: '100%',
					textAlign: 'center',
				}}
			>
				<h2>Canvas Settings</h2>
				{userOwnsSavedCanvas && !newCanvas && (
					<Tooltip
						trigger="click"
						title={
							!userOwnsSavedCanvas && !newCanvas
								? 'Requires Signed-In Canvas Owner'
								: 'Set Canvas Visibility'
						}
					>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'flex-start',
								width: '182px',
								border: 'none',
								marginTop: '1em',
							}}
						>
							<span>
								<InputGroup compact>
									<Select
										disabled={!userOwnsSavedCanvas && !newCanvas}
										value={canvas.visibility}
										onChange={this.handleVisibilityToggle}
									>
										{/* <Option value="public_edit">Anyone Can Edit</Option> */}
										{/* <Option value="public_comment">Anyone Can Comment</Option> */}
										<Option value="public_view">Anyone Can View</Option>
										<Option value="private">Private</Option>
									</Select>
								</InputGroup>
							</span>
						</div>
					</Tooltip>
				)}

				<Button
					style={{
						marginTop: '1em',
					}}
					onClick={this.download}
				>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
							width: '150px',
							border: 'none',
						}}
					>
						<i
							style={{ color: '#40A9FF', marginRight: '.5em' }}
							className="fa fa-download fa-lg"
						/>
						<span>Save As PNG</span>
					</div>
				</Button>

				<Tooltip
					trigger="click"
					title={!user ? 'Requires Signed-In User' : null}
				>
					<Button
						disabled={!user}
						style={{
							marginTop: '1em',
						}}
						onClick={() => this.props.history.push('/archive')}
					>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between',
								width: '150px',
								border: 'none',
							}}
						>
							<i
								style={{ color: '#40A9FF', marginRight: '.5em' }}
								className="fa fa-archive fa-lg"
							/>
							<span>Cloud Storage</span>
						</div>
					</Button>
				</Tooltip>

				<a
					href={`mailto:support@leanStartupCanvas.com?subject=${'Feedback'}%20From%20${'Lean%20Startup%20Canvas%20User'}&body=%0D%0AName: ${
						auth.user && auth.user.displayName ? auth.user.displayName : ''
					}%0D%0AEmail: ${
						auth.user && auth.user.email ? auth.user.email : ''
					}%0D%0A`}
				>
					<Button
						style={{
							marginTop: '1em',
						}}
					>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between',
								width: '150px',
								border: 'none',
							}}
						>
							<i
								style={{ color: '#40A9FF', marginRight: '.5em' }}
								className="fa fa-commenting fa-lg"
							/>
							<span>Send Us Feedback</span>
						</div>
					</Button>
				</a>
			</div>
		)
	}

	renderShareSettings = () => {
		const { auth } = this.props
		const { canvas } = this.props
		const { canvasMetaData, canvasSharedPermissions = {} } = canvas
		const { user } = auth
		const {
			userOwnsSavedCanvas,
			userHasViewPermissionForSavedCanvas,
			userHasEditPermissionForSavedCanvas,
			newCanvas,
		} = this.state
		const sharedUserListColumns = [
			{
				title: 'Email',
				dataIndex: 'email',
				width: 220,
			},
			{
				title: 'Permission',
				dataIndex: 'permission',
				width: 80,
				render: (text, record) => {
					return <Tag color={'green'}>{record.permission}</Tag>
				},
			},
			{
				title: 'Action',
				dataIndex: 'operation',
				width: 60,
				render: (text, record) => {
					return (
						<Button
							style={{ color: 'tomato' }}
							type="link"
							icon="delete"
							value={record.email}
							onClick={
								this.handleDeleteCanvasSharedUserPermissionsFromCloudRequest
							}
						/>
					)
				},
			},
		]
		let sharedUserListData = []

		Object.keys(canvasSharedPermissions).map((sharedUserEmail) => {
			sharedUserListData.push({
				key: sharedUserEmail,
				email: sharedUserEmail,
				permission: canvasSharedPermissions[sharedUserEmail].permission,
			})
		})
		return (
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
					width: '100%',
					textAlign: 'center',
				}}
			>
				{userOwnsSavedCanvas && !newCanvas && (
					<React.Fragment>
						<h2>Share Settings</h2>

						<Tooltip
							trigger="click"
							title={
								!userOwnsSavedCanvas && !newCanvas
									? 'Requires a Saved Canvas Owner'
									: null
							}
						>
							<span>
								<Input
									disabled={!userOwnsSavedCanvas && !newCanvas}
									type={'email'}
									onChange={(e) =>
										this.setState({ shared_user_email: e.target.value })
									}
									defaultValue=""
									value={this.state.shared_user_email}
									placeholder="User Email"
									prefix={
										<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />
									}
									addonAfter={
										<div
											style={{
												display: 'flex',
												alignItems: 'center',
											}}
										>
											<Select
												disabled={!userOwnsSavedCanvas && !newCanvas}
												id="shared_permissions_dropdown"
												defaultValue="can_view"
												value={this.state.shared_permission}
												style={{ width: 'inherent !important' }}
												onChange={(value) =>
													this.setState({ shared_permission: value })
												}
											>
												<Option value="can_view">Can View</Option>
												{/* <Option value="can_comment">Can Comment</Option> */}
												<Option value="can_edit">Can Edit</Option>
											</Select>
										</div>
									}
								/>
							</span>
						</Tooltip>

						<Button
							disabled={!userOwnsSavedCanvas && !newCanvas}
							style={{
								marginTop: '1em',
							}}
							onClick={this.handleSaveCanvasSharedUserPermissionsToCloudRequest}
						>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									border: 'none',
								}}
							>
								<span>Update Permission For User</span>
							</div>
						</Button>

						<Tooltip
							trigger="click"
							title={!canvasMetaData ? 'Requires a Saved Canvas' : null}
						>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									width: '182px',
									border: 'none',
									marginTop: '1em',
									marginBottom: '1em',
								}}
							>
								<CopyToClipboard
									style={{
										flex: '1',
									}}
									text={`https://leanStartupCanvas.com/${canvas.canvasId}`}
									onCopy={() => this.setState({ clipBoardCopied: true })}
								>
									<Button
										type="link"
										disabled={!canvasMetaData}
										style={{
											flex: '1',
										}}
									>
										<span style={{ paddingRight: '5px' }}>Copy Share Link</span>
										{this.state.clipBoardCopied && (
											<i
												style={{ color: 'tomato' }}
												className="fa fa-check fa-lg"
											/>
										)}
									</Button>
								</CopyToClipboard>
							</div>
						</Tooltip>

						<Table
							title={() => 'Shared Users List'.toUpperCase()}
							bordered
							showHeader={false}
							size={'small'}
							columns={sharedUserListColumns}
							dataSource={sharedUserListData}
							pagination={false}
							scroll={{ y: 220 }}
						/>
					</React.Fragment>
				)}
			</div>
		)
	}

	render() {
		const { auth, canvas } = this.props
		const { user } = auth
		const {
			canvasData,
			canvasLoadError,
			canvasSaveError,
			canvasMetaData,
			latestVersionCanvasMetaData,
			newVersionAvailable,
			canvasTitle,
			canvasId,
			fetching,
		} = canvas

		const {
			problem,
			solution,
			keyMetrics,
			uniqueValueProp,
			unfairAdvantage,
			channels,
			customerSegments,
			costStructure,
			revenueStreams,
		} = canvasData
		const {
			showSettingsModal,
			showShareSettingsModal,
			showCreatingBlankCanvasModal,
			showSignOutModal,
			showDeleteCanvasModal,
			userOwnsSavedCanvas,
			userHasViewPermissionForSavedCanvas,
			userHasEditPermissionForSavedCanvas,
			newCanvas,
		} = this.state
		return (
			<div style={styles.container} id="divToPrint">
				{this.setUserPermissionsForCanvas()}
				<Modal
					visible={showSettingsModal}
					onCancel={this.handleHideModal}
					footer={null}
				>
					{this.renderSettings()}
				</Modal>
				<Modal
					visible={showShareSettingsModal}
					onCancel={this.handleHideModal}
					footer={null}
				>
					{this.renderShareSettings()}
				</Modal>
				<Modal
					visible={showCreatingBlankCanvasModal}
					onOk={this.handleCreatingBlankCanvas}
					onCancel={this.handleHideModal}
					okText={`I'm Sure`}
					okType={'danger'}
				>
					<span style={{ fontSize: '1.2em' }}>
						Unsaved Canvas Data will be lost. Are You Sure?
					</span>
				</Modal>
				<Modal
					visible={showDeleteCanvasModal}
					onOk={this.deleteCanvasFromCloudRequest}
					onCancel={this.handleHideModal}
					okText={'Delete'}
					okType={'danger'}
				>
					<span style={{ fontSize: '1.2em', color: '#D50000' }}>
						This will delete all versions of the canvas completely. This process
						is irreversible. Are you Sure ?
					</span>
				</Modal>
				<Modal
					visible={showSignOutModal}
					onOk={this.handleSignOut}
					onCancel={this.handleHideModal}
					okText={'Sign-Out'}
				>
					<span style={{ fontSize: '1.2em' }}>
						Unsaved Canvas Data will be lost. Are You Sure?
					</span>
				</Modal>
				{this.handleNewVersionPromptShow()}
				{canvasLoadError && this.handleCanvasLoadError()}
				{this.handleRetryAddingListener()}
				<span
					style={{
						display: 'flex',
						position: 'absolute',
						alignItems: 'center',
						justifyContent: 'flex-start',
						left: '3vw',
						top: '1vh',
						color: '#f2f2f2',
						fontSize: '0.65em',
					}}
				>
					<Avatar
						size="small"
						icon="user"
						alt={user && user.displayName ? user.displayName : 'Anonymous'}
						src={user && user.photoURL ? user.photoURL : ''}
						style={{ marginRight: '5px' }}
					/>
					<span className={'hideOnMobile'}>
						{user && user.displayName ? (
							<Text ellipsis={true} style={{ color: '#f2f2f2' }}>
								{user.displayName}
							</Text>
						) : (
							<Text style={{ color: '#f2f2f2' }}>Anonymous</Text>
						)}
					</span>
				</span>

				<span
					style={{
						display: 'flex',
						position: 'absolute',
						alignItems: 'center',
						justifyContent: 'center',
						textAlign: 'center',
						width: '100%',
						top: '2vh',
						color: '#f2f2f2',
						fontSize: '0.65em',
					}}
				>
					<span className={'hideOnMobile'}>
						<Text
							editable={
								userOwnsSavedCanvas ||
								newCanvas ||
								userHasEditPermissionForSavedCanvas
									? {
											onChange: this.canvasTitleOnChange,
									  }
									: false
							}
						>
							<Text style={{ color: '#f2f2f2' }}>{canvasTitle}</Text>
							{!newCanvas &&
								!userHasEditPermissionForSavedCanvas &&
								!userOwnsSavedCanvas && (
									<Tooltip title={'View Only Canvas'}>
										<span style={{ color: '#f2f2f2', marginLeft: '5px' }}>
											<i style={{ color: '#f2f2f2' }} className="fa fa-eye" />
										</span>
									</Tooltip>
								)}
						</Text>
					</span>
				</span>

				<span
					style={{
						display: 'flex',
						position: 'absolute',
						alignItems: 'center',
						justifyContent: 'flex-end',
						right: '3vw',
						top: '2vh',
						color: '#f2f2f2',
						fontSize: '0.65em',
					}}
				>
					{newVersionAvailable && (
						<Popconfirm
							placement="bottomRight"
							title={`New Version of this Canvas is available. Want to Load it? Unsaved changes will be lost! `}
							onConfirm={this.loadLatestVersionOfCanvas}
							okText="Yes"
							cancelText="No"
						>
							<span style={{ marginRight: '5px', cursor: 'pointer' }}>
								<Badge dot status="error" title="New version available">
									<i
										style={{ color: '#f2f2f2' }}
										className="fa fa-bell fa-lg"
									/>
								</Badge>
							</span>
						</Popconfirm>
					)}
					<span style={{ marginLeft: '5px' }}>
						{canvasMetaData && canvasMetaData.version
							? `version: #${canvasMetaData.version} |`
							: 'unsaved'}
					</span>
					<span style={{ marginLeft: '5px' }}>
						{canvasMetaData &&
						canvasMetaData.createdAtTimeStamp &&
						canvasMetaData.createdAtTimeStamp.seconds
							? `saved: ${Helpers.toLocalDateTime(
									canvasMetaData.createdAtTimeStamp.seconds
							  )}`
							: null}
					</span>
				</span>
				<Spin
					spinning={fetching}
					style={{ color: '#f2f2f2' }}
					delay={420}
					tip="thinking..."
				>
					<div id="canvas-wrapper">
						<div className="row row-1">
							<section className="key-partners full-box">
								<section className="box-header">
									<Tooltip
										placement="bottom"
										trigger="click"
										title={
											problem !== ''
												? () => (
														<div>
															<div>List your top 1-3 problems.</div>
															<div>EXISTING ALTERNATIVES</div>
															<div>
																List how these problems are solved today.
															</div>
														</div>
												  )
												: null
										}
									>
										<i
											style={{ cursor: problem !== '' ? 'help' : 'auto' }}
											className="fa fa-exclamation-circle fa-lg"
										/>
									</Tooltip>
									<h2>Problem</h2>
								</section>
								<TextArea
									style={{
										pointerEvents:
											userOwnsSavedCanvas ||
											newCanvas ||
											userHasEditPermissionForSavedCanvas
												? 'auto'
												: 'none',
									}}
									onChange={this.onChange}
									value={problem}
									className="key-partners-area text-area"
									autoComplete="off"
									id="problem"
									placeholder={`List your top 1-3 problems.\n\n\n\n\n\nEXISTING ALTERNATIVES\nList how these problems are solved today.`}
								/>
							</section>
						</div>

						<div className="row row-2">
							<section className="key-activities half-box-top">
								<section className="box-header">
									<Tooltip
										placement="bottom"
										trigger="click"
										title={
											solution !== ''
												? () => (
														<div>
															<div>
																Outline a possible solution to each problem.
															</div>
														</div>
												  )
												: null
										}
									>
										<i
											style={{ cursor: solution !== '' ? 'help' : 'auto' }}
											className="fa fa-cogs fa-lg"
										/>
									</Tooltip>
									<h2>Solution</h2>
								</section>
								<TextArea
									style={{
										pointerEvents:
											userOwnsSavedCanvas ||
											newCanvas ||
											userHasEditPermissionForSavedCanvas
												? 'auto'
												: 'none',
									}}
									onChange={this.onChange}
									value={solution}
									className="key-activities-area text-area"
									autoComplete="off"
									id="solution"
									placeholder="Outline a possible solution to each problem."
								/>
							</section>

							<section className="key-resources half-box-bottom">
								<section className="box-header">
									<Tooltip
										placement="bottom"
										trigger="click"
										title={
											keyMetrics !== ''
												? () => (
														<div>
															<div>
																List the Key numbers that tell you how your
																business is doing.
															</div>
														</div>
												  )
												: null
										}
									>
										<i
											style={{ cursor: keyMetrics !== '' ? 'help' : 'auto' }}
											className="fa fa-area-chart fa-lg"
										/>
									</Tooltip>
									<h2>Key Metrics</h2>
								</section>
								<TextArea
									style={{
										pointerEvents:
											userOwnsSavedCanvas ||
											newCanvas ||
											userHasEditPermissionForSavedCanvas
												? 'auto'
												: 'none',
									}}
									onChange={this.onChange}
									value={keyMetrics}
									className="key-resources-area text-area"
									autoComplete="off"
									id="keyMetrics"
									placeholder="List the Key numbers that tell you how your business is doing."
								/>
							</section>
						</div>

						<div className="row row-3">
							<section className="value-proposition full-box">
								<section className="box-header">
									<Tooltip
										placement="bottom"
										trigger="click"
										title={
											uniqueValueProp !== ''
												? () => (
														<div>
															<div>
																Single, clear, compelling message that states
																why you are different and worth paying
																attention.
															</div>
															<div>HIGH-LEVEL CONCEPT</div>
															<div>
																List your X for Y analogy e.g YouTube = Flicker
																for videos.
															</div>
														</div>
												  )
												: null
										}
									>
										<i
											style={{
												cursor: uniqueValueProp !== '' ? 'help' : 'auto',
											}}
											className="fa fa-money fa-lg"
										/>
									</Tooltip>
									<h2>Unique Value Prop</h2>
								</section>
								<TextArea
									style={{
										pointerEvents:
											userOwnsSavedCanvas ||
											newCanvas ||
											userHasEditPermissionForSavedCanvas
												? 'auto'
												: 'none',
									}}
									onChange={this.onChange}
									value={uniqueValueProp}
									className="value-proposition-area text-area"
									autoComplete="off"
									id="uniqueValueProp"
									placeholder={`Single, clear, compelling message that states why you are different and worth paying attention.\n\n\n\n\nHIGH-LEVEL CONCEPT\nList your X for Y analogy e.g YouTube = Flicker for videos.`}
								/>
							</section>
						</div>

						<div className="row row-4">
							<section className="customer-relationships half-box-top">
								<section className="box-header">
									<Tooltip
										placement="bottom"
										trigger="click"
										title={
											unfairAdvantage !== ''
												? () => (
														<div>
															<div>
																Something that cannot be easily bought or
																copied.
															</div>
														</div>
												  )
												: null
										}
									>
										<i
											style={{
												cursor: unfairAdvantage !== '' ? 'help' : 'auto',
											}}
											className="fa fa-heart fa-lg"
										/>
									</Tooltip>
									<h2>Unfair Advantage</h2>
								</section>
								<TextArea
									style={{
										pointerEvents:
											userOwnsSavedCanvas ||
											newCanvas ||
											userHasEditPermissionForSavedCanvas
												? 'auto'
												: 'none',
									}}
									onChange={this.onChange}
									value={unfairAdvantage}
									className="customer-relationships-area text-area"
									autoComplete="off"
									id="unfairAdvantage"
									placeholder="Something that cannot be easily bought or copied."
								/>
							</section>

							<section className="channels half-box-bottom">
								<section className="box-header">
									<Tooltip
										placement="bottom"
										trigger="click"
										title={
											channels !== ''
												? () => (
														<div>
															<div>List your path to customers.</div>
														</div>
												  )
												: null
										}
									>
										<i
											style={{ cursor: channels !== '' ? 'help' : 'auto' }}
											className="fa fa-truck fa-lg"
										/>
									</Tooltip>
									<h2>Channels</h2>
								</section>
								<TextArea
									style={{
										pointerEvents:
											userOwnsSavedCanvas ||
											newCanvas ||
											userHasEditPermissionForSavedCanvas
												? 'auto'
												: 'none',
									}}
									onChange={this.onChange}
									value={channels}
									className="channels-area text-area"
									autoComplete="off"
									id="channels"
									placeholder="List your path to customers."
								/>
							</section>
						</div>

						<div className="row row-5">
							<section className="customer-segments full-box">
								<section className="box-header">
									<Tooltip
										placement="bottom"
										trigger="click"
										title={
											customerSegments !== ''
												? () => (
														<div>
															<div>List your target customers and users.</div>
															<div>EARLY ADOPTERS</div>
															<div>
																List the characteristics of you ideal customers.
															</div>
														</div>
												  )
												: null
										}
									>
										<i
											style={{
												cursor: customerSegments !== '' ? 'help' : 'auto',
											}}
											className="fa fa-users fa-lg"
										/>
									</Tooltip>
									<h2>Customers</h2>
								</section>
								<TextArea
									style={{
										pointerEvents:
											userOwnsSavedCanvas ||
											newCanvas ||
											userHasEditPermissionForSavedCanvas
												? 'auto'
												: 'none',
									}}
									onChange={this.onChange}
									value={customerSegments}
									className="customer-segments-area text-area"
									autoComplete="off"
									id="customerSegments"
									placeholder={`List your target customers and users.\n\n\n\n\n\nEARLY ADOPTERS\nList the characteristics of you ideal customers.`}
								/>
							</section>
						</div>

						<div className="column column-1">
							<section className="cost-structure full-horizontal-box">
								<section className="box-header">
									<Tooltip
										placement="bottom"
										trigger="click"
										title={
											costStructure !== ''
												? () => (
														<div>
															<div>List your fixed and variable costs.</div>
														</div>
												  )
												: null
										}
									>
										<i
											style={{ cursor: costStructure !== '' ? 'help' : 'auto' }}
											className="fa fa-file-text fa-lg"
										/>
									</Tooltip>
									<h2>Cost Structure</h2>
								</section>
								<TextArea
									style={{
										pointerEvents:
											userOwnsSavedCanvas ||
											newCanvas ||
											userHasEditPermissionForSavedCanvas
												? 'auto'
												: 'none',
									}}
									onChange={this.onChange}
									value={costStructure}
									className="cost-structure-area text-area"
									autoComplete="off"
									id="costStructure"
									placeholder="List your fixed and variable costs."
								/>
							</section>
						</div>

						<div className="column column-2">
							<section className="revenue-streams full-horizontal-box">
								<section className="box-header">
									<Tooltip
										placement="bottom"
										trigger="click"
										title={
											revenueStreams !== ''
												? () => (
														<div>
															<div>List your sources of revenue.</div>
														</div>
												  )
												: null
										}
									>
										<i
											style={{
												cursor: revenueStreams !== '' ? 'help' : 'auto',
											}}
											className="fa fa-calculator fa-lg"
										/>
									</Tooltip>
									<h2>Revenue Streams</h2>
								</section>
								<TextArea
									style={{
										pointerEvents:
											userOwnsSavedCanvas ||
											newCanvas ||
											userHasEditPermissionForSavedCanvas
												? 'auto'
												: 'none',
									}}
									onChange={this.onChange}
									value={revenueStreams}
									className="revenue-streams-area text-area"
									autoComplete="off"
									id="revenueStreams"
									placeholder="List your sources of revenue."
								/>
							</section>
						</div>
					</div>
				</Spin>

				<div
					style={{
						position: 'fixed',
						bottom: '2vh',
						width: '100%',
						textAlign: 'center',
						color: '#f2f2f2',
						marginTop: '2em !important',
						fontSize: '0.62em',
					}}
				>
					<span>Lean Canvas originally created by Ash Maurya</span>
				</div>
				<div className="actionButton">
					{!user ? (
						<button
							className="buttons"
							tooltip="Sign-In with Google"
							onClick={this.signinWithGoogleRequest}
						>
							<i style={{ color: '#f2f2f2' }} className="fa fa-google fa-lg" />
						</button>
					) : (
						<button
							className="buttons"
							tooltip="Sign-Out"
							onClick={this.handleShowSignOutModal}
						>
							<i
								style={{ color: '#f2f2f2' }}
								className="fa fa-sign-out fa-lg"
							/>
						</button>
					)}
					<button
						className="buttons"
						tooltip="Settings"
						onClick={this.handleShowSettingsModal}
					>
						<i style={{ color: '#f2f2f2' }} className="fa fa-wrench fa-lg" />
					</button>
					<button
						className="buttons"
						tooltip="Blank New"
						onClick={this.handleShowCreatingBlankCanvasModal}
					>
						<i style={{ color: '#f2f2f2' }} className="fa fa-file fa-lg" />
					</button>

					{userOwnsSavedCanvas && !newCanvas && (
						<button
							className="buttons"
							tooltip="Share"
							value={canvasId}
							onClick={this.handleShowShareSettingsModal}
						>
							<i
								style={{ color: '#f2f2f2' }}
								className="fa fa-user-plus fa-lg"
							/>
						</button>
					)}
					{userOwnsSavedCanvas && !newCanvas && (
						<button
							className="buttons"
							tooltip="Delete From Cloud"
							value={canvasId}
							onClick={this.handleShowDeleteCanvasModal}
						>
							<i style={{ color: '#f2f2f2' }} className="fa fa-trash fa-lg" />
						</button>
					)}
					<button
						className="buttons"
						tooltip="Save To Cloud"
						onClick={this.saveCanvasToCloudRequest}
					>
						<i
							style={{ color: '#f2f2f2' }}
							className="fa fa-cloud-upload fa-lg"
						/>
					</button>

					<button className="buttons" tooltip="Actions">
						<i style={{ color: '#f2f2f2' }} className="fa fa-bars fa-2x" />
					</button>
				</div>
			</div>
		)
	}
}

function mapStateToProps({ canvas, auth }, props) {
	return {
		auth,
		canvas,
	}
}
// wraps dispatch to create nicer functions to call within our component
const mapDispatchToProps = (dispatch) => ({
	setCanvasId: ({ data }) => dispatch(CanvasActions.setCanvasId({ data })),
	updateCanvasLocally: ({ data }) =>
		dispatch(CanvasActions.updateCanvasLocally({ data })),
	updateCanvasTitleLocally: ({ data }) =>
		dispatch(CanvasActions.updateCanvasTitleLocally({ data })),
	updateNewCanvasVersionAvailable: ({ data }) =>
		dispatch(CanvasActions.updateNewCanvasVersionAvailable({ data })),
	storeLatestVersionCanvasLocally: ({ data }) =>
		dispatch(CanvasActions.storeLatestVersionCanvasLocally({ data })),
	clearCanvasLocally: () => dispatch(CanvasActions.clearCanvasLocally()),
	saveCanvasToCloudRequest: ({ data }) =>
		dispatch(CanvasActions.saveCanvasToCloudRequest({ data })),
	loadCanvasFromCloudRequest: ({ data }) =>
		dispatch(CanvasActions.loadCanvasFromCloudRequest({ data })),
	deleteCanvasFromCloudRequest: ({ data }) =>
		dispatch(CanvasActions.deleteCanvasFromCloudRequest({ data })),
	saveCanvasVisibilityToCloudRequest: ({ data }) =>
		dispatch(CanvasActions.saveCanvasVisibilityToCloudRequest({ data })),
	loadCanvasTopLevelDataFromCloudRequest: ({ data }) =>
		dispatch(CanvasActions.loadCanvasTopLevelDataFromCloudRequest({ data })),
	loadCanvasSharedPermissionsFromCloudRequest: ({ data }) =>
		dispatch(
			CanvasActions.loadCanvasSharedPermissionsFromCloudRequest({ data })
		),
	saveCanvasSharedUserPermissionsToCloudRequest: ({ data }) =>
		dispatch(
			CanvasActions.saveCanvasSharedUserPermissionsToCloudRequest({ data })
		),
	deleteCanvasSharedUserPermissionsFromCloudRequest: ({ data }) =>
		dispatch(
			CanvasActions.deleteCanvasSharedUserPermissionsFromCloudRequest({ data })
		),
	signinWithGoogleRequest: () =>
		dispatch(AuthActions.signinWithGoogleRequest()),
	signoutRequest: () => dispatch(AuthActions.signoutRequest()),
})
export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(LeanCanvas)
)

const styles = {
	container: {
		flex: 1,
		alignItems: 'center',
		justifyContent: 'center',
	},
}

import firebase, { firebaseAuth, firestore } from '../Config/Firebase'
import Helpers from '../Utils/Helpers'
import { store } from '../Containers/App'
import AuthActions from '../Redux/AuthRedux'
import CanvasActions from '../Redux/CanvasRedux'
export async function getShowerThought() {
  // uses Reddit api to fetch top shower thought
  try {
    let response = await fetch(
      'https://www.reddit.com/r/showerthoughts/top/.json?count=25',
    )
    let responseJson = await response.json()
    return responseJson.data.children[Math.floor(Math.random() * 24)].data.title
  } catch (error) {
    // console.error(error)
  }
}
let unsubscribeUser
export async function signInWithGoogle() {
  try {
    const provider = new firebase.auth.GoogleAuthProvider()
    await firebaseAuth.signInWithPopup(provider)
    const user = firebaseAuth.currentUser
    updateUserDataInDatabase(user)
    return user
  } catch (error) {
    // console.error('signInWithGoogle error:', error)
    throw error
  }
}

export async function updateUserDataInDatabase(userData) {
  const updates = {}
  const lastUpdatedAtTimeStamp = firebase.firestore.FieldValue.serverTimestamp()
  const usersRef = firestore.collection('users').doc(`${userData.uid}`)
  updates[`displayName`] = userData.displayName || null
  updates[`email`] = userData.email || null
  updates[`emailVerified`] = userData.emailVerified || false
  updates[`lastUpdatedAtTimeStamp`] = lastUpdatedAtTimeStamp || null
  updates[`photoURL`] = userData.photoURL || null
  updates[`phoneNumber`] = userData.phoneNumber || null
  updates[`uid`] = userData.uid || null

  await usersRef.set({ ...updates }, { merge: true })
  unsubscribeUser = addListenerOnUser(userData)
}

export function addListenerOnUser(user) {
  if (user && user.uid) {
    const { uid } = user
    const unsubscribe = firestore
      .collection('users')
      .doc(`${uid}`)
      .onSnapshot(
        querySnapshot => {
          const user = querySnapshot.data()
          if (user) {
            const data = { user }
            store.dispatch(AuthActions.updateUserLocally({ data }))
            let { ownedCanvases = [], sharedCanvases = {} } = user

            ownedCanvases = ownedCanvases.filter(Boolean)
            let sharedCanvasesArray = Object.keys(sharedCanvases)

            sharedCanvasesArray = sharedCanvasesArray.filter(Boolean)

            // const canvasIdArray = [...ownedCanvases, ...sharedCanvases]
            const uniqueSharedCanvasIdArray = [...new Set(sharedCanvasesArray)]
            const uniqueOwnedCanvasesIdArray = [...new Set(ownedCanvases)]

            // console.log('====================================')
            // get data for each canvasId async
            if (uniqueSharedCanvasIdArray.length > 0) {
              getBatchSharedCanvasesObject({
                canvasIds: uniqueSharedCanvasIdArray,
              })
            }
            if (uniqueOwnedCanvasesIdArray.length > 0) {
              getBatchOwnedCanvasesObject({
                canvasIds: uniqueOwnedCanvasesIdArray,
              })
            }
          }
        },
        error => {
          // handle error if needed
          signOutUser()
        },
      )
    return unsubscribe
  }
}

async function getBatchSharedCanvasesObject({ canvasIds }) {
  const refs = canvasIds.map(canvasId =>
    firestore
      .collection('canvases')
      .doc(`${canvasId}`)
      .collection('versions')
      .where('version', '>', 0)
      .orderBy('version', 'desc')
      .limit(1),
  )

  // console.log('refs:', refs)
  refs.forEach(ref => {
    ref
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          // console.log('canvas:', doc.data())
          const data = { canvas: doc.data(), sharedCanvases: canvasIds }
          store.dispatch(
            CanvasActions.updateArchivedCanvasesObjectLocally({ data }),
          )
        })
      })
      .catch(error => {
        // console.log('Error getting detailed shared canvases documents: ', error)
      })
  })
}

async function getBatchOwnedCanvasesObject({ canvasIds }) {
  const refs = canvasIds.map(canvasId =>
    firestore
      .collection('canvases')
      .doc(`${canvasId}`)
      .collection('versions')
      .where('version', '>', 0)
      .orderBy('version', 'desc')
      .limit(1),
  )

  // console.log('refs:', refs)
  refs.forEach(ref => {
    ref
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          const canvasData = doc.data()
          let data = { canvas: canvasData }
          const {
            shared,
            visibility,
            versionCount,
          } = loadCanvasTopLevelDataFromCloud({ data })
          data = {
            ...data,
            shared,
            visibility,
            versionCount,
            ownedCanvases: canvasIds,
          }
          store.dispatch(
            CanvasActions.updateArchivedCanvasesObjectLocally({ data }),
          )
        })
      })
      .catch(error => {
        // console.log('Error getting detailed owned Canvases documents: ', error)
      })
  })
}

export async function getUserDataFromDatabase(user) {
  const { uid } = user
  const usersRef = firestore.collection('users').doc(`${uid}`)
  const response = await usersRef.get()
  const userData = response.data()
  return userData
}

export async function signOutUser() {
  // logs user out of firebase
  try {
    const response = await firebaseAuth.signOut()
    if (unsubscribeUser) {
      unsubscribeUser()
    }
    return response
  } catch (error) {
    throw error
  }
}

export async function saveCanvasToCloud({ data }) {
  try {
    const { canvasData, canvasId = '', canvasTitle = 'Untitled Canvas' } = data
    const { user } = store.getState().auth
    let updates = {}
    let changesExist = false
    let newCanvas = true
    let nextVersionNumber = null
    let createdAtTimeStamp = null
    let editor = null
    let owner = user.uid
    const versionCount = await getTotalCanvasVersionsCount(canvasId)
    if (versionCount === 0) {
      // its a new canvas
      updates[`owner`] = owner
      nextVersionNumber = versionCount + 1
      createdAtTimeStamp = firebase.firestore.FieldValue.serverTimestamp()
      editor = user.uid
    } else {
      newCanvas = false
      const previousVersionCanvas = await getSpecificCanvasVersion({
        canvasId,
        version: versionCount,
      })

      if (previousVersionCanvas) {
        owner =
          previousVersionCanvas && previousVersionCanvas.owner
            ? previousVersionCanvas.owner
            : owner
      }

      // // get diff i.e changes made
      const changes = Helpers.checkJSONDiff(
        {
          ...previousVersionCanvas.canvasData,
          canvasTitle: previousVersionCanvas.canvasTitle,
        },
        { ...canvasData, canvasTitle },
      )
      const { removed, added } = changes

      if (Object.keys(removed).length > 0 || Object.keys(added).length > 0) {
        // console.log('canvas changed')
        updates[`changes`] = changes
        changesExist = true
        nextVersionNumber = versionCount + 1
        createdAtTimeStamp = firebase.firestore.FieldValue.serverTimestamp()
        editor = user.uid
        updates[`owner`] = owner || null
      } else {
        updates = data
      }
    }

    if ((changesExist && !newCanvas) || newCanvas) {
      const canvasVersionRef = firestore
        .collection('canvases')
        .doc(`${canvasId}`)
        .collection('versions')
        .doc(`${nextVersionNumber}`)
      updates[`createdAtTimeStamp`] = createdAtTimeStamp || null
      updates[`version`] = nextVersionNumber || null
      updates[`editor`] = editor || null
      updates[`canvasData`] = canvasData || null
      updates[`canvasId`] = canvasId || null
      updates[`canvasTitle`] = canvasTitle || null

      // const { user } = store.getState().auth
      // const usersRef = firestore.collection('users').doc(`${user.uid}`)
      const canvasRef = firestore.collection('canvases').doc(`${canvasId}`)
      const versionCount = firebase.firestore.FieldValue.increment(1)
      // batch writes for new canvas
      const batch = firestore.batch()
      batch.set(canvasVersionRef, { ...updates })
      if (newCanvas) {
        const { visibility } = store.getState().canvas
        batch.set(
          canvasRef,
          { owner, visibility, versionCount },
          { merge: true },
        )
        await batch.commit()
      } else if (changesExist) {
        batch.set(canvasRef, { versionCount }, { merge: true })
        await batch.commit()
      }
      return updates
    } else {
      const error = 'Canvas is identical to the latest version.'
      throw error
    }
  } catch (error) {
    // console.error(error)
    throw error
  }
}

export function addListenerOnCanvas(canvasId, CB) {
  let changedCanvas
  const unsubscribe = firestore
    .collection('canvases')
    .doc(`${canvasId}`)
    .collection('versions')
    .where('version', '>', 0)
    .orderBy('version', 'desc')
    .limit(1)
    .onSnapshot(
      querySnapshot => {
        querySnapshot.forEach(doc => {
          changedCanvas = doc.data()
        })

        if (changedCanvas) {
          if (
            (changedCanvas.createdAtTimeStamp,
            changedCanvas.canvasId && changedCanvas.version)
          ) {
            // success CB
            CB(null, { canvasId, changedCanvas })
          }
        }
      },
      error => {
        // error CB occurred
        CB('listener error', { canvasId, changedCanvas })
      },
    )
  return unsubscribe
}

export async function getTotalCanvasVersionsCount(canvasId) {
  try {
    const canvasRef = firestore
      .collection('canvases')
      .doc(`${canvasId}`)
      .collection('versions')
      .where('version', '>', 0)
      .orderBy('version', 'desc')
      .limit(1)

    const response = await canvasRef.get()
    let canvasData
    if (response) {
      response.forEach(doc => {
        canvasData = doc.data()
      })
    }
    if (canvasData && canvasData.version) {
      const versionCount = canvasData.version
      return versionCount
    } else {
      return 0
    }
  } catch (error) {
    // console.log(error)
    return 0
  }
}

export async function getSpecificCanvasVersion({ canvasId, version }) {
  try {
    const canvasRef = firestore
      .collection('canvases')
      .doc(`${canvasId}`)
      .collection('versions')
      .doc(`${version}`)
    const response = await canvasRef.get()
    const canvasData = response.data()
    return canvasData
  } catch (error) {
    // console.log(error)
    throw error
  }
}

export async function loadLatestCanvasVersionFromCloud({ data }) {
  try {
    const { canvasId } = data
    const canvasRef = firestore
      .collection('canvases')
      .doc(`${canvasId}`)
      .collection('versions')
      .where('version', '>', 0)
      .orderBy('version', 'desc')
      .limit(1)
    const response = await canvasRef.get()
    let canvasData
    if (response) {
      response.forEach(doc => {
        canvasData = doc.data()
      })
    }
    if (canvasData) {
      return canvasData
    } else {
      const error = 'Error Loading Canvas from Cloud.'
      throw error
    }
  } catch (err) {
    // console.log('loadLatestCanvasVersionFromCloud:', err)
    const error = 'Error Loading Canvas from Cloud.'
    throw error
  }
}

export async function saveCanvasVisibilityToCloud({ data }) {
  const { canvasId, visibility } = data
  try {
    const canvasRef = firestore.collection('canvases').doc(`${canvasId}`)
    const updates = {}
    updates[`visibility`] = visibility
    await canvasRef.set(updates, { merge: true })
    return visibility
  } catch (error) {
    // console.log(error)
    throw error
  }
}

export async function loadCanvasSharedPermissionsFromCloud({ data }) {
  const { canvasId } = data
  // console.log('====================================')
  // console.log('loadCanvasSharedPermissionsFromCloud ran with id:', canvasId)
  // console.log('====================================')
  try {
    const canvasShareRef = firestore
      .collection('canvases')
      .doc(`${canvasId}`)
      .collection('shared')

    const response = await canvasShareRef.get()
    let canvasSharedPermissions = {}
    if (response) {
      response.forEach(doc => {
        const sharedUserData = doc.data()
        canvasSharedPermissions[doc.id] = sharedUserData
      })
      // console.log('====================================')
      // console.log(
      //   'loadCanvasSharedPermissionsFromCloud canvasSharedPermissions:',
      //   canvasSharedPermissions,
      // )
      // console.log('====================================')
      return canvasSharedPermissions
    }
  } catch (error) {
    // console.log('loadCanvasSharedPermissionsFromCloud:', error)
    throw error
  }
}

export async function saveCanvasSharedUserPermissionsToCloud({ data }) {
  const { canvasId, shared_user_email, shared_permission } = data
  try {
    const canvasShareRef = firestore
      .collection('canvases')
      .doc(`${canvasId}`)
      .collection('shared')
      .doc(`${shared_user_email}`)
    const updates = {}
    updates['permission'] = shared_permission
    await canvasShareRef.set({ ...updates }, { merge: true })
    return data
  } catch (error) {
    // console.log('saveCanvasSharedUserPermissionsToCloud ERROR:', error)
    throw error
  }
}

export async function deleteCanvasSharedUserPermissionsFromCloud({ data }) {
  const { canvasId, shared_user_email, shared_permission } = data
  try {
    const canvasShareRef = firestore
      .collection('canvases')
      .doc(`${canvasId}`)
      .collection('shared')
      .doc(`${shared_user_email}`)
    await canvasShareRef.delete()
    return data
  } catch (error) {
    // console.log('deleteCanvasSharedUserPermissionsFromCloud ERROR:', error)
    throw error
  }
}

export async function loadCanvasTopLevelDataFromCloud({ data }) {
  const { canvasId } = data
  try {
    const canvasRef = firestore.collection('canvases').doc(`${canvasId}`)
    const response = await canvasRef.get()
    const canvasData = response.data()
    return canvasData
  } catch (error) {
    // console.log(error)
    throw error
  }
}

export async function deleteCanvasFromCloud({ data }) {
  const { canvasId } = data

  try {
    const canvasRef = firestore.collection('canvases').doc(`${canvasId}`)
    await canvasRef.delete()
    return canvasId
  } catch (error) {
    // console.log(error)
    throw error
  }
}

import React, { Component } from 'react'
import RootNavigation from '../Navigation/RootNavigation'
import { BrowserRouter as Router } from 'react-router-dom'
import ReduxPersist from '../Config/ReduxPersist'
import { connect } from 'react-redux'
import StartupActions from '../Redux/StartupRedux'
import NetworkStatusActions from '../Redux/NetworkStatusRedux'
import { OFFLINE, ONLINE } from 'redux-offline-queue'
import { Detector } from 'react-detect-offline'

class RootContainer extends Component {
  componentDidMount() {
    // if redux persist is not active fire startup action
    if (!ReduxPersist.active) {
      this.props.startup()
    }
  }

  handleNetworkStatusChange = newStatus => {
    const { online } = this.props.networkStatus
    if (newStatus !== online) {
      if (newStatus) {
        this.props.networkOnline()
      } else {
        this.props.networkOffline()
      }
    }
  }

  render() {
    return (
      <Router>
        <RootNavigation />
        <Detector
          render={({ online }) => (
            <div>
              {null}
              {this.handleNetworkStatusChange(online)}
            </div>
          )}
        />
      </Router>
    )
  }
}

function mapStateToProps({ networkStatus }, props) {
  return {
    networkStatus,
  }
}

// wraps dispatch to create nicer functions to call within our component
const mapDispatchToProps = dispatch => ({
  startup: () => dispatch(StartupActions.startup()),
  networkOnline: () => {
    dispatch(NetworkStatusActions.networkOnline())
    dispatch({ type: ONLINE })
  },
  networkOffline: () => {
    dispatch(NetworkStatusActions.networkOffline())
    dispatch({ type: OFFLINE })
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RootContainer)

import { createActions, createReducer } from 'reduxsauce'
import { markActionsOffline } from 'redux-offline-queue'
import produce from 'immer'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  networkOnline: null,
  networkOffline: null,
})

export const AuthTypes = Types
export default Creators

/* ------------- Reducer ------------- */

const INITIAL_STATE = {
  online: true,
}

export const handleSettingOnline = (state = INITIAL_STATE, action) => {
  return produce(state, draft => {
    draft.online = true
  })
}
export const handleSettingOffline = (state = INITIAL_STATE, action) => {
  return produce(state, draft => {
    draft.online = false
  })
}

// map our action types to our reducer functions
export const HANDLERS = {
  [Types.NETWORK_ONLINE]: handleSettingOnline,
  [Types.NETWORK_OFFLINE]: handleSettingOffline,
}

export const reducer = createReducer(INITIAL_STATE, HANDLERS)

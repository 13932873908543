import { put, takeLatest, call } from 'redux-saga/effects'
import { RedditTypes } from '../Redux/RedditRedux'
import RedditActions from '../Redux/RedditRedux'
import * as API from '../Services/API'

/* ------------- Worker Sagas ------------- */
function* showerThought(action) {
  try {
    // sets loading to true
    yield put(RedditActions.showerThoughtPending())
    // makes api call
    const response = yield call(API.getShowerThought)
    // dispatch a success action to the store with the new showerThought
    yield put(RedditActions.showerThoughtFulfilled(response))
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put(RedditActions.showerThoughtRejected(error))
  }
}

/* ------------- Watcher Sagas ------------- */

export const redditSagas = [
  // some sagas only receive an action
  takeLatest(RedditTypes.SHOWER_THOUGHT_REQUEST, showerThought),
]

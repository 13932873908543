/* eslint-disable require-yield */
import { put, select, takeLatest } from 'redux-saga/effects'
import { StartupTypes } from '../Redux/StartupRedux'

/* ------------- Worker Sagas ------------- */
function* startup(action) {
  if (process.env.NODE_ENV === 'development' && console.tron) {
    // straight-up string logging
    console.tron.log('App just Started Up. You can do some task here')
  }
}

/* ------------- Watcher Sagas ------------- */

export const startupSagas = [
  // some sagas only receive an action
  takeLatest(StartupTypes.STARTUP, startup),
]

import { createActions, createReducer } from 'reduxsauce'
import { markActionsOffline } from 'redux-offline-queue'
import produce from 'immer'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  showerThoughtRequest: null,
  showerThoughtPending: null,
  showerThoughtFulfilled: ['showerThought'],
  showerThoughtRejected: ['error'],
})

// THe following makes showerThoughtRequest a action that will be saved to offline queue if network is offline
// and ran once network connection is restored
markActionsOffline(Creators, ['showerThoughtRequest'])

export const RedditTypes = Types
export default Creators

/* ------------- Reducer ------------- */

const INITIAL_STATE = {
  showerThought: null,
  fetching: false,
  error: null,
}

export const handleShowerThoughtPending = (state = INITIAL_STATE, action) => {
  return produce(state, draft => {
    draft.fetching = true
  })
}

export const handleShowerThoughtFulfilled = (state = INITIAL_STATE, action) => {
  return produce(state, draft => {
    draft.fetching = false
    draft.showerThought = action.showerThought
    draft.error = null
  })
}

export const handleShowerThoughtRejected = (state = INITIAL_STATE, action) => {
  return produce(state, draft => {
    draft.fetching = false
    draft.showerThought = null
    draft.error = action.error
  })
}

// map our action types to our reducer functions
export const HANDLERS = {
  [Types.SHOWER_THOUGHT_PENDING]: handleShowerThoughtPending,
  [Types.SHOWER_THOUGHT_FULFILLED]: handleShowerThoughtFulfilled,
  [Types.SHOWER_THOUGHT_REJECTED]: handleShowerThoughtRejected,
}

export const reducer = createReducer(INITIAL_STATE, HANDLERS)

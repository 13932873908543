import React from 'react'
import { Route, Redirect, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

// isAuthenticated is passed as prop here
const ProtectedRoute = ({ component: Component, isAuthenticated, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        return isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location },
            }}
          />
        )
      }}
    />
  )
}

const mapStateToProps = state => ({
  // isAuthenticated  value is get from here
  isAuthenticated: state.auth.user,
})

export default withRouter(
  connect(
    mapStateToProps,
    null,
    null,
    { pure: false },
  )(ProtectedRoute),
)

import React, { Component } from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { connect } from 'react-redux'
import RootContainer from './RootContainer'
import createStore from '../Redux'
import Loading from '../Components/Loading'
import { message } from 'antd'
// create our store
export const { store, persistor } = createStore()

/**
 * Provides an entry point into our application.  Both index.ios.js and index.android.js
 * call this component first.
 *
 * We create our Redux store here, put it into a provider and then bring in our
 * RootContainer.
 *
 * We separate like this to play nice with React Native's hot reloading.
 */
class App extends Component {
  componentDidMount() {
    const { innerWidth: width, innerHeight: height } = window
    if (width && width < 700) {
      message.info('Recommended use on larger screen devices.')
    }
  }
  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={<Loading />} persistor={persistor}>
          <RootContainer />
        </PersistGate>
      </Provider>
    )
  }
}

export default App

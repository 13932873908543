const generate = require('nanoid/generate')
const Diff = require('diff')

function generateCanvasId() {
  const alphabet =
    '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
  return generate(alphabet, 28)
}

function toLocalDateTime(secs) {
  var utcSeconds = secs
  var d = new Date(0) // The 0 there is the key, which sets the date to the epoch
  d.setUTCSeconds(utcSeconds)
  var hours = d.getHours()
  var minutes = d.getMinutes()
  var ampm = hours >= 12 ? 'pm' : 'am'
  hours = hours % 12
  hours = hours ? hours : 12 // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes
  var day = d.getDate()
  var month = d.getMonth() + 1
  var year = d.getFullYear()

  return `${month}/${day}/${year}  ${hours}:${minutes} ${ampm}`
}

// uses JSDiff to get changes made in JSON Object
function checkJSONDiff(oldString, newString) {
  const diff = Diff.diffJson(oldString, newString)
  // console.log("diff:", diff);

  let removed = ''
  let added = ''
  diff.forEach(part => {
    // green for additions, red for deletions
    // var color = part.added ? "green" : part.removed ? "red" : null;
    if (part.added) {
      added += part.value
      // console.log("part added:", part.value);
    }
    if (part.removed) {
      removed += part.value
      // console.log("part removed:", part.value);
    }
  })

  // console.log('removed:', removed)
  // console.log('added:', added)

  const cleaned_removed = removed.replace(/,\s*$/, '').trim()
  const cleaned_added = added.replace(/,\s*$/, '').trim()

  const removed_object = JSON.parse(`{${cleaned_removed}}`)

  const added_object = JSON.parse(`{${cleaned_added}}`)

  return {
    added: added_object,
    removed: removed_object,
  }
}

function areSetsEqual(a, b) {
  return a.size === b.size && [...a].every(value => b.has(value))
}

function validateEmail(email) {
  var re = /^(?:[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+\.)*[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!\.)){0,61}[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/
  return re.test(email)
}

export default {
  generateCanvasId,
  toLocalDateTime,
  checkJSONDiff,
  areSetsEqual,
  validateEmail,
}

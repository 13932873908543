import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import CanvasActions from '../Redux/CanvasRedux'
import { Button, Card, Spin, Collapse, Modal, Tooltip, Divider } from 'antd'
import Helpers from '../Utils/Helpers'
import { addListenerOnUser } from '../Services/API'

const { Panel } = Collapse

class Archive extends Component {
	state = {
		key: 'ownedCanvases',
		showDeleteCanvasModal: false,
		canvasIdToBeDeleted: null,
	}

	onTabChange = (key, type) => {
		// console.log(key, type)
		this.setState({ key })
	}

	componentDidMount() {
		const { auth } = this.props
		const { user } = auth
		if (user) {
			addListenerOnUser(user)
		}
	}

	handleShowDeleteCanvasModal = (e) => {
		const canvasId = e.target.value
		this.setState({
			showDeleteCanvasModal: true,
			canvasIdToBeDeleted: canvasId,
		})
	}

	handleHideModal = () => {
		this.setState({
			showDeleteCanvasModal: false,
			canvasIdToBeDeleted: null,
		})
	}

	deleteCanvasFromCloudRequest = (e) => {
		const { canvasIdToBeDeleted } = this.state
		if (canvasIdToBeDeleted) {
			const data = { canvasId: canvasIdToBeDeleted }
			// console.log('User Wants to Delete the following Canvas with Id: ', canvasId)
			this.props.deleteCanvasFromCloudRequest({ data })
			this.setState({ showDeleteCanvasModal: false, canvasIdToBeDeleted: null })

			if (canvasIdToBeDeleted === this.props.canvas.canvasId) {
				this.props.clearCanvasLocally()
			}
		}
	}

	renderOwnedCanvasesList = () => {
		const { canvas, auth } = this.props
		const { archivedCanvasesObject = {}, canvasId: currentWorkingCanvasId } =
			canvas
		const { user } = auth
		let { ownedCanvases = [] } = user
		return ownedCanvases.length > 0 ? (
			ownedCanvases.map((canvasId) => (
				<div style={{ textAlign: 'center' }} key={canvasId}>
					{archivedCanvasesObject[canvasId] ? (
						<div style={{ textAlign: 'center' }}>
							<div>
								<strong>Title:</strong>{' '}
								{`${JSON.stringify(
									archivedCanvasesObject[canvasId].canvasTitle,
									null,
									2
								)}`}
							</div>
							<div>
								<strong>ID:</strong> {canvasId}
							</div>
							<div>
								<strong>Last Updated:&nbsp;</strong>
								{Helpers.toLocalDateTime(
									archivedCanvasesObject[canvasId].createdAtTimeStamp.seconds
								)}
							</div>
							<div style={{ marginTop: '2vh' }}>
								<Tooltip
									placement="left"
									title={
										currentWorkingCanvasId === canvasId
											? `Can't delete Open Canvas From Archive`
											: null
									}
								>
									<Button
										disabled={currentWorkingCanvasId === canvasId}
										icon="delete"
										type="danger"
										value={canvasId}
										onClick={
											currentWorkingCanvasId !== canvasId
												? this.handleShowDeleteCanvasModal
												: null
										}
									>
										Delete
									</Button>
								</Tooltip>
								<Button
									icon="eye"
									onClick={() => this.props.history.push(`/${canvasId}`)}
									style={{ marginLeft: '5vw' }}
								>
									View
								</Button>
							</div>
							<Divider dashed />
						</div>
					) : null}
				</div>
			))
		) : (
			<p style={{ marginTop: '2vh', textAlign: 'center' }}>
				You currently don't own any canvases.
			</p>
		)
	}

	renderAllSharedCanvasesList = () => {
		const { canvas, auth } = this.props
		const { archivedCanvasesObject = {} } = canvas
		const { user } = auth
		const { sharedCanvases = {} } = user
		let sharedCanvasesArray = Object.keys(sharedCanvases)
		sharedCanvasesArray = sharedCanvasesArray.filter(Boolean)
		sharedCanvasesArray = [...new Set(sharedCanvasesArray)]

		return sharedCanvasesArray.length > 0 ? (
			sharedCanvasesArray.map((canvasId) => (
				<div style={{ textAlign: 'center' }} key={canvasId}>
					{archivedCanvasesObject[canvasId] ? (
						<div style={{ textAlign: 'center' }}>
							<div>
								<strong>Title:</strong>{' '}
								{`${JSON.stringify(
									archivedCanvasesObject[canvasId].canvasTitle,
									null,
									2
								)}`}
							</div>
							<div>
								<strong>ID:</strong> {canvasId}
							</div>
							<div>
								<strong>Last Updated:&nbsp;</strong>
								{Helpers.toLocalDateTime(
									archivedCanvasesObject[canvasId].createdAtTimeStamp.seconds
								)}
							</div>
							<div
								style={{
									marginTop: '2vh',
								}}
							>
								<Button
									icon="eye"
									onClick={() => this.props.history.push(`/${canvasId}`)}
								>
									View
								</Button>
							</div>
							<Divider dashed />
						</div>
					) : null}
				</div>
			))
		) : (
			<p style={{ marginTop: '2vh', textAlign: 'center' }}>
				You currently don't have any shared canvases.
			</p>
		)
	}
	render() {
		const { canvas, auth } = this.props
		const { showDeleteCanvasModal } = this.state
		const { fetching } = canvas
		const tabList = [
			{
				key: 'ownedCanvases',
				tab: 'YOU OWN',
			},
			{
				key: 'sharedCanvases',
				tab: 'SHARED WITH YOU',
			},
		]
		const contentList = {
			ownedCanvases: this.renderOwnedCanvasesList(),
			sharedCanvases: this.renderAllSharedCanvasesList(),
		}

		return (
			<div style={styles.container}>
				<Modal
					visible={showDeleteCanvasModal}
					onOk={this.deleteCanvasFromCloudRequest}
					onCancel={this.handleHideModal}
					okText={'Delete'}
					okType={'danger'}
				>
					<span style={{ fontSize: '1.2em', color: '#D50000' }}>
						This will delete all versions of the canvas completely. This process
						is irreversible. Are you Sure ?
					</span>
				</Modal>
				<Card
					style={{ width: '100%', minHeight: '100vh' }}
					title="Cloud Canvases"
					tabList={tabList}
					activeTabKey={this.state.key}
					onTabChange={(key) => {
						this.onTabChange(key, 'key')
					}}
				>
					<span
						style={{
							position: 'absolute',
							top: '2.6vh',
							left: '5vw',
							cursor: 'pointer',
							zIndex: '999',
						}}
						onClick={() => this.props.history.goBack()}
					>
						<i className="fa fa-arrow-circle-left fa-3x" />
					</span>
					{contentList[this.state.key]}
				</Card>
				)}
			</div>
		)
	}
}

function mapStateToProps({ auth, canvas }, props) {
	return {
		auth,
		canvas,
	}
}

// wraps dispatch to create nicer functions to call within our component
const mapDispatchToProps = (dispatch) => ({
	deleteCanvasFromCloudRequest: ({ data }) =>
		dispatch(CanvasActions.deleteCanvasFromCloudRequest({ data })),
	clearCanvasLocally: () => dispatch(CanvasActions.clearCanvasLocally()),
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Archive))

const styles = {
	container: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		flexDirection: 'column',
	},
}

import { createActions, createReducer } from 'reduxsauce'
import { markActionsOffline } from 'redux-offline-queue'
import produce from 'immer'
import { message } from 'antd'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  setCanvasId: { data: null },
  updateCanvasLocally: { data: null },
  updateCanvasTitleLocally: { data: null },
  updateNewCanvasVersionAvailable: { data: null },
  storeLatestVersionCanvasLocally: { data: null },
  clearCanvasLocally: null,
  saveCanvasToCloudRequest: { data: null },
  saveCanvasToCloudPending: null,
  saveCanvasToCloudFulfilled: ['canvas'],
  saveCanvasToCloudRejected: ['error'],
  loadCanvasFromCloudRequest: { data: null },
  loadCanvasFromCloudPending: null,
  loadCanvasFromCloudFulfilled: ['canvas'],
  loadCanvasFromCloudRejected: ['error'],
  saveCanvasVisibilityToCloudRequest: { data: null },
  saveCanvasVisibilityToCloudPending: null,
  saveCanvasVisibilityToCloudFulfilled: ['visibility'],
  saveCanvasVisibilityToCloudRejected: ['error'],
  loadCanvasTopLevelDataFromCloudRequest: { data: null },
  loadCanvasTopLevelDataFromCloudPending: null,
  loadCanvasTopLevelDataFromCloudFulfilled: ['topLevelCanvasData'],
  loadCanvasTopLevelDataFromCloudRejected: ['error'],
  deleteCanvasFromCloudRequest: { data: null },
  deleteCanvasFromCloudPending: null,
  deleteCanvasFromCloudFulfilled: ['canvasId'],
  deleteCanvasFromCloudRejected: ['error'],
  updateArchivedCanvasesObjectLocally: { data: null },
  saveCanvasSharedUserPermissionsToCloudRequest: { data: null },
  saveCanvasSharedUserPermissionsToCloudPending: null,
  saveCanvasSharedUserPermissionsToCloudFulfilled: ['userSharedPermission'],
  saveCanvasSharedUserPermissionsToCloudRejected: ['error'],
  deleteCanvasSharedUserPermissionsFromCloudRequest: { data: null },
  deleteCanvasSharedUserPermissionsFromCloudPending: null,
  deleteCanvasSharedUserPermissionsFromCloudFulfilled: ['userSharedPermission'],
  deleteCanvasSharedUserPermissionsFromCloudRejected: ['error'],
  loadCanvasSharedPermissionsFromCloudRequest: { data: null },
  loadCanvasSharedPermissionsFromCloudPending: null,
  loadCanvasSharedPermissionsFromCloudFulfilled: ['canvasSharedPermissions'],
  loadCanvasSharedPermissionsFromCloudRejected: ['error'],
})

export const CanvasTypes = Types
export default Creators

/* ------------- Reducer ------------- */

const INITIAL_STATE = {
  fetching: false,
  canvasId: null,
  canvasTitle: 'Untitled Canvas',
  canvasLoadError: null,
  canvasSaveError: null,
  archiveLoadError: null,
  canvasData: {
    problem: '',
    solution: '',
    keyMetrics: '',
    uniqueValueProp: '',
    unfairAdvantage: '',
    channels: '',
    customerSegments: '',
    costStructure: '',
    revenueStreams: '',
  },
  canvasMetaData: null,
  canvasVisibilitySaveError: null,
  canvasVisibilityLoadError: null,
  visibility: 'private',
  newVersionAvailable: false,
  latestVersionCanvasData: {
    problem: '',
    solution: '',
    keyMetrics: '',
    uniqueValueProp: '',
    unfairAdvantage: '',
    channels: '',
    customerSegments: '',
    costStructure: '',
    revenueStreams: '',
  },
  latestVersionCanvasId: null,
  latestVersionCanvasTitle: null,
  latestVersionCanvasMetaData: null,
  archivedCanvasesObject: {},
  canvasDeleteError: null,
  canvasSharedPermissions: {},
  canvasSharedPermissionsError: null,
  canvasSharedUserPermissionError: null,
}
export const setCanvasId = (state = INITIAL_STATE, action) => {
  return produce(state, draft => {
    draft.canvasId = action.data
  })
}

export const handleUpdateCanvasLocally = (state = INITIAL_STATE, action) => {
  const { canvasData, canvasMetaData } = action.data

  return produce(state, draft => {
    draft.canvasData = { ...canvasData }
    draft.canvasMetaData = canvasMetaData ? { ...canvasMetaData } : null
  })
}

export const handleUpdateCanvasTitleLocally = (
  state = INITIAL_STATE,
  action,
) => {
  const { canvasTitle } = action.data

  return produce(state, draft => {
    draft.canvasTitle = canvasTitle
  })
}

export const handleUpdateNewCanvasVersionAvailable = (
  state = INITIAL_STATE,
  action,
) => {
  const { newVersionAvailable } = action.data

  return produce(state, draft => {
    draft.newVersionAvailable = newVersionAvailable
  })
}

export const handleUpdateArchivedCanvasesObjectLocally = (
  state = INITIAL_STATE,
  action,
) => {
  const { canvas, sharedCanvases = [], ownedCanvases = [] } = action.data
  const { canvasId } = canvas
  const totalAccessibleCanvases = [...ownedCanvases, ...sharedCanvases]

  return produce(state, draft => {
    draft.archivedCanvasesObject[canvasId] = canvas
    // const existingArchivedCanvasesObjectKeys = Object.keys(
    //   draft.archivedCanvasesObject,
    // )
    // existingArchivedCanvasesObjectKeys.map(existingCanvasId => {
    //   if (!totalAccessibleCanvases.includes(existingCanvasId)) {
    //     delete draft.archivedCanvasesObject[existingCanvasId]
    //   }
    // })
  })
}

export const handleStoringLatestVersionCanvasLocally = (
  state = INITIAL_STATE,
  action,
) => {
  const { canvasData, canvasId, canvasTitle, ...canvasMetaData } = action.data
  return produce(state, draft => {
    draft.latestVersionCanvasData = { ...canvasData }
    draft.latestVersionCanvasTitle = canvasTitle
    draft.latestVersionCanvasId = canvasId
    draft.latestVersionCanvasMetaData = {
      ...canvasMetaData,
    }
  })
}

export const handleClearingCanvasLocally = (state = INITIAL_STATE, action) => {
  return produce(state, draft => {
    draft.canvasData = {
      problem: '',
      solution: '',
      keyMetrics: '',
      uniqueValueProp: '',
      unfairAdvantage: '',
      channels: '',
      customerSegments: '',
      costStructure: '',
      revenueStreams: '',
    }
    draft.canvasMetaData = null
    draft.canvasSaveError = null
    draft.canvasLoadError = null
    draft.canvasVisibilitySaveError = null
    draft.canvasVisibilityLoadError = null
    draft.visibility = 'private'
    draft.newVersionAvailable = false
    draft.latestVersionCanvasData = {
      problem: '',
      solution: '',
      keyMetrics: '',
      uniqueValueProp: '',
      unfairAdvantage: '',
      channels: '',
      customerSegments: '',
      costStructure: '',
      revenueStreams: '',
    }
    draft.latestVersionCanvasTitle = null
    draft.canvasTitle = 'Untitled Canvas'
    draft.canvasId = null
    draft.latestVersionCanvasId = null
    draft.latestVersionCanvasMetaData = null
    draft.archiveLoadError = null
    draft.archivedCanvasesObject = {}
    draft.canvasSharedPermissions = {}
    draft.canvasSharedPermissionsError = null
    draft.canvasSharedUserPermissionError = null
  })
}
export const handleSaveCanvasToCloudPending = (
  state = INITIAL_STATE,
  action,
) => {
  return produce(state, draft => {
    draft.fetching = true
    draft.canvasSaveError = null
  })
}

export const handleSaveCanvasToCloudFulfilled = (
  state = INITIAL_STATE,
  action,
) => {
  const { canvasData, canvasId, canvasTitle, ...canvasMetaData } = action.canvas
  const currentTimeInSec = new Date().getTime() / 1000
  return produce(state, draft => {
    draft.fetching = false
    draft.canvasSaveError = null
    draft.canvasData = { ...canvasData }
    draft.canvasId = canvasId
    draft.canvasTitle = canvasTitle
    draft.canvasMetaData = {
      ...canvasMetaData,
      createdAtTimeStamp: { seconds: currentTimeInSec },
    }
    draft.newVersionAvailable = false
  })
}

export const handleSaveCanvasToCloudRejected = (
  state = INITIAL_STATE,
  action,
) => {
  return produce(state, draft => {
    draft.fetching = false
    draft.canvasSaveError = action.error
  })
}

export const handleLoadCanvasFromCloudPending = (
  state = INITIAL_STATE,
  action,
) => {
  return produce(state, draft => {
    draft.fetching = true
    draft.canvasLoadError = null
  })
}

export const handleLoadCanvasFromCloudFulfilled = (
  state = INITIAL_STATE,
  action,
) => {
  const { canvasData, canvasId, canvasTitle, ...canvasMetaData } = action.canvas
  return produce(state, draft => {
    draft.fetching = false
    draft.canvasLoadError = null
    draft.canvasData = { ...canvasData }
    draft.canvasId = canvasId
    draft.canvasTitle = canvasTitle
    draft.canvasMetaData = { ...canvasMetaData }
  })
}

export const handleLoadCanvasFromCloudRejected = (
  state = INITIAL_STATE,
  action,
) => {
  return produce(state, draft => {
    draft.fetching = false
    draft.canvasLoadError = action.error
  })
}

export const handleSaveCanvasVisibilityToCloudPending = (
  state = INITIAL_STATE,
  action,
) => {
  return produce(state, draft => {
    draft.fetching = true
    draft.canvasVisibilitySaveError = null
  })
}

export const handleSaveCanvasVisibilityToCloudFulfilled = (
  state = INITIAL_STATE,
  action,
) => {
  const { visibility } = action
  return produce(state, draft => {
    draft.fetching = false
    draft.canvasVisibilitySaveError = null
    draft.visibility = visibility
  })
}

export const handleSaveCanvasVisibilityToCloudRejected = (
  state = INITIAL_STATE,
  action,
) => {
  return produce(state, draft => {
    draft.fetching = false
    draft.canvasVisibilitySaveError = action.error
  })
}

export const handleloadCanvasTopLevelDataFromCloudPending = (
  state = INITIAL_STATE,
  action,
) => {
  return produce(state, draft => {
    draft.fetching = true
    draft.canvasVisibilityLoadError = null
  })
}

export const handleloadCanvasTopLevelDataFromCloudFulfilled = (
  state = INITIAL_STATE,
  action,
) => {
  const { visibility, owner, versionCount } = action.topLevelCanvasData
  return produce(state, draft => {
    draft.fetching = false
    draft.canvasVisibilityLoadError = null
    draft.visibility = visibility
    // draft.canvasMetaData.owner = owner
    // draft.canvasMetaData.versionCount = versionCount
  })
}

export const handleloadCanvasTopLevelDataFromCloudRejected = (
  state = INITIAL_STATE,
  action,
) => {
  return produce(state, draft => {
    draft.fetching = false
    draft.visibility = 'private'
    draft.canvasVisibilityLoadError = action.error
  })
}

export const handleDeleteCanvasFromCloudPending = (
  state = INITIAL_STATE,
  action,
) => {
  return produce(state, draft => {
    draft.fetching = true
    draft.canvasDeleteError = null
  })
}

export const handleDeleteCanvasFromCloudFulfilled = (
  state = INITIAL_STATE,
  action,
) => {
  const { canvasId } = action
  return produce(state, draft => {
    draft.fetching = false
    draft.canvasDeleteError = null
    // // deletes value from object
    delete draft.archivedCanvasesObject[canvasId]
  })
}

export const handleDeleteCanvasFromCloudRejected = (
  state = INITIAL_STATE,
  action,
) => {
  return produce(state, draft => {
    draft.fetching = false
    draft.canvasDeleteError = action.error
  })
}

export const handleSaveCanvasSharedUserPermissionsToCloudPending = (
  state = INITIAL_STATE,
  action,
) => {
  return produce(state, draft => {
    draft.fetching = true
    draft.canvasSharedUserPermissionError = null
  })
}

export const handleSaveCanvasSharedUserPermissionsToCloudFulfilled = (
  state = INITIAL_STATE,
  action,
) => {
  const {
    canvasId,
    shared_user_email,
    shared_permission,
  } = action.userSharedPermission
  return produce(state, draft => {
    draft.fetching = false
    draft.canvasSharedUserPermissionError = null
    draft.canvasSharedPermissions[shared_user_email] = {
      permission: shared_permission,
    }
  })
}

export const handleSaveCanvasSharedUserPermissionsToCloudRejected = (
  state = INITIAL_STATE,
  action,
) => {
  return produce(state, draft => {
    draft.fetching = false
    draft.canvasSharedUserPermissionError = action.error
  })
}

export const handleDeleteCanvasSharedUserPermissionsFromCloudPending = (
  state = INITIAL_STATE,
  action,
) => {
  return produce(state, draft => {
    draft.fetching = true
    draft.canvasSharedUserPermissionError = null
  })
}

export const handleDeleteCanvasSharedUserPermissionsFromCloudFulfilled = (
  state = INITIAL_STATE,
  action,
) => {
  const {
    canvasId,
    shared_user_email,
    shared_permission,
  } = action.userSharedPermission
  return produce(state, draft => {
    draft.fetching = false
    draft.canvasSharedUserPermissionError = null
    delete draft.canvasSharedPermissions[shared_user_email]
  })
}

export const handleDeleteCanvasSharedUserPermissionsFromCloudRejected = (
  state = INITIAL_STATE,
  action,
) => {
  return produce(state, draft => {
    draft.fetching = false
    draft.canvasSharedUserPermissionError = action.error
  })
}

export const handleLoadCanvasSharedPermissionsFromCloudPending = (
  state = INITIAL_STATE,
  action,
) => {
  return produce(state, draft => {
    draft.fetching = true
    draft.canvasSharedPermissionsError = null
  })
}

export const handleLoadCanvasSharedPermissionsFromCloudFulfilled = (
  state = INITIAL_STATE,
  action,
) => {
  const { canvasSharedPermissions } = action
  return produce(state, draft => {
    draft.fetching = false
    draft.canvasSharedPermissionsError = null
    draft.canvasSharedPermissions = canvasSharedPermissions
  })
}

export const handleLoadCanvasSharedPermissionsFromCloudRejected = (
  state = INITIAL_STATE,
  action,
) => {
  return produce(state, draft => {
    draft.fetching = false
    draft.canvasSharedPermissions = {}
    draft.canvasSharedPermissionsError = action.error
  })
}

// map our action types to our reducer functions
export const HANDLERS = {
  [Types.SET_CANVAS_ID]: setCanvasId,
  [Types.UPDATE_CANVAS_LOCALLY]: handleUpdateCanvasLocally,
  [Types.UPDATE_CANVAS_TITLE_LOCALLY]: handleUpdateCanvasTitleLocally,
  [Types.UPDATE_ARCHIVED_CANVASES_OBJECT_LOCALLY]: handleUpdateArchivedCanvasesObjectLocally,
  [Types.UPDATE_NEW_CANVAS_VERSION_AVAILABLE]: handleUpdateNewCanvasVersionAvailable,
  [Types.STORE_LATEST_VERSION_CANVAS_LOCALLY]: handleStoringLatestVersionCanvasLocally,
  [Types.CLEAR_CANVAS_LOCALLY]: handleClearingCanvasLocally,
  [Types.SAVE_CANVAS_TO_CLOUD_PENDING]: handleSaveCanvasToCloudPending,
  [Types.SAVE_CANVAS_TO_CLOUD_FULFILLED]: handleSaveCanvasToCloudFulfilled,
  [Types.SAVE_CANVAS_TO_CLOUD_REJECTED]: handleSaveCanvasToCloudRejected,
  [Types.LOAD_CANVAS_FROM_CLOUD_PENDING]: handleLoadCanvasFromCloudPending,
  [Types.LOAD_CANVAS_FROM_CLOUD_FULFILLED]: handleLoadCanvasFromCloudFulfilled,
  [Types.LOAD_CANVAS_FROM_CLOUD_REJECTED]: handleLoadCanvasFromCloudRejected,
  [Types.SAVE_CANVAS_VISIBILITY_TO_CLOUD_PENDING]: handleSaveCanvasVisibilityToCloudPending,
  [Types.SAVE_CANVAS_VISIBILITY_TO_CLOUD_FULFILLED]: handleSaveCanvasVisibilityToCloudFulfilled,
  [Types.SAVE_CANVAS_VISIBILITY_TO_CLOUD_REJECTED]: handleSaveCanvasVisibilityToCloudRejected,
  [Types.LOAD_CANVAS_TOP_LEVEL_DATA_FROM_CLOUD_PENDING]: handleloadCanvasTopLevelDataFromCloudPending,
  [Types.LOAD_CANVAS_TOP_LEVEL_DATA_FROM_CLOUD_FULFILLED]: handleloadCanvasTopLevelDataFromCloudFulfilled,
  [Types.LOAD_CANVAS_TOP_LEVEL_DATA_FROM_CLOUD_REJECTED]: handleloadCanvasTopLevelDataFromCloudRejected,
  [Types.DELETE_CANVAS_FROM_CLOUD_PENDING]: handleDeleteCanvasFromCloudPending,
  [Types.DELETE_CANVAS_FROM_CLOUD_FULFILLED]: handleDeleteCanvasFromCloudFulfilled,
  [Types.DELETE_CANVAS_FROM_CLOUD_REJECTED]: handleDeleteCanvasFromCloudRejected,

  [Types.LOAD_CANVAS_SHARED_PERMISSIONS_FROM_CLOUD_PENDING]: handleLoadCanvasSharedPermissionsFromCloudPending,
  [Types.LOAD_CANVAS_SHARED_PERMISSIONS_FROM_CLOUD_FULFILLED]: handleLoadCanvasSharedPermissionsFromCloudFulfilled,
  [Types.LOAD_CANVAS_SHARED_PERMISSIONS_FROM_CLOUD_REJECTED]: handleLoadCanvasSharedPermissionsFromCloudRejected,

  [Types.SAVE_CANVAS_SHARED_USER_PERMISSIONS_TO_CLOUD_PENDING]: handleSaveCanvasSharedUserPermissionsToCloudPending,
  [Types.SAVE_CANVAS_SHARED_USER_PERMISSIONS_TO_CLOUD_FULFILLED]: handleSaveCanvasSharedUserPermissionsToCloudFulfilled,
  [Types.SAVE_CANVAS_SHARED_USER_PERMISSIONS_TO_CLOUD_REJECTED]: handleSaveCanvasSharedUserPermissionsToCloudRejected,

  [Types.DELETE_CANVAS_SHARED_USER_PERMISSIONS_FROM_CLOUD_PENDING]: handleDeleteCanvasSharedUserPermissionsFromCloudPending,
  [Types.DELETE_CANVAS_SHARED_USER_PERMISSIONS_FROM_CLOUD_FULFILLED]: handleDeleteCanvasSharedUserPermissionsFromCloudFulfilled,
  [Types.DELETE_CANVAS_SHARED_USER_PERMISSIONS_FROM_CLOUD_REJECTED]: handleDeleteCanvasSharedUserPermissionsFromCloudRejected,
}
export const reducer = createReducer(INITIAL_STATE, HANDLERS)

import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

export let config

config = {
  apiKey: 'AIzaSyBr0QvPqTEpR5In7pdD4wpgKFfFP8IlxNc',
  authDomain: 'leanstartupcanvas.com',
  databaseURL: 'https://leanstartupcanvas.firebaseio.com',
  projectId: 'leanstartupcanvas',
  storageBucket: 'leanstartupcanvas.appspot.com',
  messagingSenderId: '546891163455',
  appId: '1:546891163455:web:5eec27008035d249',
}

firebase.initializeApp(config)

// Get a reference to the database service
export const firebaseAuth = firebase.auth()
export const firestore = firebase.firestore()
export default firebase

import { call, put, takeLatest } from 'redux-saga/effects'
import { AuthTypes } from '../Redux/AuthRedux'
import AuthActions from '../Redux/AuthRedux'
import * as API from '../Services/API'
import { message } from 'antd'

/* ------------- Worker Sagas ------------- */

function* signInWithGoogle(action) {
  try {
    // sets loading to true
    yield put(AuthActions.signinWithGooglePending())
    // makes api call
    const response = yield call(API.signInWithGoogle)
    // dispatch a success action to the store with the new signup
    message.success('User Signed In.')

    yield put(AuthActions.signinWithGoogleFulfilled(response))
  } catch (error) {
    // console.error('signin error:', error)
    if (typeof error === 'string') {
      message.error(error)
    } else {
      const err = 'Error Signing In'
      message.error(err)
    }
    // dispatch a failure action to the store with the error
    yield put(AuthActions.signinWithGoogleRejected(error))
  }
}

function* signOutUser(action) {
  try {
    // sets loading to true
    yield put(AuthActions.signoutPending())
    // makes api call
    yield call(API.signOutUser)
    message.info('User Signed Out.')

    // dispatch a success action to the store with the new signup
    yield put(AuthActions.signoutFulfilled())
  } catch (error) {
    if (typeof error === 'string') {
      message.error(error)
    } else {
      message.error('Error occurred Signing Out.')
    }

    // dispatch a failure action to the store with the error
    yield put(AuthActions.signoutRejected(error))
  }
}
/* ------------- Watcher Sagas ------------- */
export const authSagas = [
  // some sagas only receive an action
  // some sagas only receive an action
  takeLatest(AuthTypes.SIGNIN_WITH_GOOGLE_REQUEST, signInWithGoogle),
  takeLatest(AuthTypes.SIGNOUT_REQUEST, signOutUser),
]

import React from 'react'

const Loading = () => {
  return <div style={styles.container}>...loading</div>
}
const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    flexDirection: 'column',
  },
}

export default Loading

const migrations = {}

export default migrations

// example
// const migrations = {
//   0: state => {
//     // migration clear out device state
//     return {
//       ...state,
//       auth: { user: state.auth.user, random: 'hello' },
//     }
//   },
//   1: state => {
//     // migration to keep only device state
//     return {
//       ...state,
//       auth: { user: state.auth.user, random: 'hello', up: 'down' },
//     }
//   },
//   2: state => {
//     // migration to keep only device state
//     return {
//       ...state,
//       auth: { user: state.auth.user },
//     }
//   },
// }

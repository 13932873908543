import { all } from 'redux-saga/effects'

/* ------------- Sagas ------------- */
import { startupSagas } from './StartupSagas'
import { authSagas } from './AuthSagas'
import { canvasSagas } from './CanvasSagas'
import { redditSagas } from './RedditSagas'

/* ------------- Combine All Sagas in Single RootSaga ------------- */

export default function* rootSaga() {
  yield all([...startupSagas, ...authSagas, ...canvasSagas, ...redditSagas])
}

import { createActions, createReducer } from 'reduxsauce'
import { markActionsOffline } from 'redux-offline-queue'
import produce from 'immer'
import { message } from 'antd'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  signinWithGoogleRequest: null,
  signinWithGooglePending: null,
  signinWithGoogleFulfilled: ['user'],
  signinWithGoogleRejected: ['error'],
  signoutRequest: null,
  signoutPending: null,
  signoutFulfilled: null,
  signoutRejected: ['error'],
  updateUserLocally: { data: null },
})

// THe following makes showerThoughtRequest a action that will be saved to offline queue if network is offline
// and ran once network connection is restored
// markActionsOffline(Creators, [
//   'signupRequest',
//   'signinRequest',
//   'signoutRequest',
// ])

export const AuthTypes = Types
export default Creators

/* ------------- Reducer ------------- */

const INITIAL_STATE = {
  user: null,
  fetching: false,
  error: null,
}

export const handleSigninWithGooglePending = (
  state = INITIAL_STATE,
  action,
) => {
  return produce(state, draft => {
    draft.fetching = true
  })
}

export const handleSigninWithGoogleFulfilled = (
  state = INITIAL_STATE,
  action,
) => {
  return produce(state, draft => {
    draft.fetching = false
    draft.user = action.user
    draft.error = null
  })
}

export const handleSigninWithGoogleRejected = (
  state = INITIAL_STATE,
  action,
) => {
  return produce(state, draft => {
    draft.fetching = false
    draft.user = null
    draft.error = action.error
  })
}

export const handleSignoutPending = (state = INITIAL_STATE, action) => {
  return produce(state, draft => {
    draft.fetching = true
  })
}

export const handleSignoutFulfilled = (state = INITIAL_STATE, action) => {
  return produce(state, draft => {
    draft.fetching = false
    draft.user = null
    draft.error = null
  })
}

export const handleSignoutRejected = (state = INITIAL_STATE, action) => {
  return produce(state, draft => {
    draft.fetching = false
    draft.user = null
    draft.error = action.error
  })
}

export const handleUpdateUserLocally = (state = INITIAL_STATE, action) => {
  const { user } = action.data

  return produce(state, draft => {
    draft.user = { ...user }
  })
}

// map our action types to our reducer functions
export const HANDLERS = {
  [Types.SIGNIN_WITH_GOOGLE_PENDING]: handleSigninWithGooglePending,
  [Types.SIGNIN_WITH_GOOGLE_FULFILLED]: handleSigninWithGoogleFulfilled,
  [Types.SIGNIN_WITH_GOOGLE_REJECTED]: handleSigninWithGoogleRejected,
  [Types.SIGNOUT_PENDING]: handleSignoutPending,
  [Types.SIGNOUT_FULFILLED]: handleSignoutFulfilled,
  [Types.SIGNOUT_REJECTED]: handleSignoutRejected,
  [Types.UPDATE_USER_LOCALLY]: handleUpdateUserLocally,
}

export const reducer = createReducer(INITIAL_STATE, HANDLERS)

import '../../Config/ReactotronConfig'
import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { persistStore } from 'redux-persist'
import {
	offlineMiddleware,
	suspendSaga,
	consumeActionMiddleware,
} from 'redux-offline-queue'
import StartupActions from '../StartupRedux'
// import { analyticsMiddleware } from '../Middleware/Analytics'

// creates the store
export default (rootReducer, rootSaga) => {
	/* ------------- Redux Configuration ------------- */

	const middleware = []
	const enhancers = []

	/* ------------- Analytics Middleware ------------- */

	// middleware.push(analyticsMiddleware)

	/* ------------- Analytics Middleware ------------- */

	/* ------------- redux-offline-queue offline Middleware ------------- */

	middleware.push(
		offlineMiddleware({
			stateName: 'offline',
		})
	)

	/* ------------- Saga Middleware ------------- */

	const sagaMonitor =
		process.env.NODE_ENV === 'development' && console.tron
			? console.tron.createSagaMonitor()
			: null

	const sagaMiddleware = createSagaMiddleware({
		sagaMonitor,
	})
	const suspendSagaMiddleware = suspendSaga(sagaMiddleware)

	middleware.push(suspendSagaMiddleware)

	/** The consume middleware should be placed last.
	 * We allow the previous middlewares (especially the saga middleware) to react to the action
	 * before it is eventually consumed.
	 */

	/* ------------- redux-offline-queue  consume Middleware ------------- */

	middleware.push(consumeActionMiddleware())

	/* ------------- Assemble Middleware ------------- */

	enhancers.push(applyMiddleware(...middleware))

	// if in development enable redux-devtools
	const composeEnhancers =
		global.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
		process.env.NODE_ENV === 'development'
			? global.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
			: compose

	const store = createStore(rootReducer, composeEnhancers(...enhancers))

	const startup = () => store.dispatch(StartupActions.startup())

	const persistor = persistStore(store, null, startup)
	// kick off root saga
	let sagasManager = sagaMiddleware.run(rootSaga)

	return {
		store,
		persistor,
		sagasManager,
		sagaMiddleware,
	}
}
